import React, { Suspense, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useStores } from 'stores/globals'
import { Modal } from 'components/common/modal'
import { ApplyStore } from 'stores/apply'
import { observer } from 'mobx-react'
import {
  usePositionDetail,
  useScoutMyRequest,
  useTempApplication,
  useUser,
} from 'apis/hooks'
import { ApplyForm } from 'components/positions/apply-form'
import { ApplyDone } from 'components/positions/apply-done'
import { Column } from 'components/common/layout'

export const PositionApplyModal = observer(() => {
  const { applyModalStore } = useStores()
  if (!applyModalStore.isOpen) return null
  return (
    <Suspense>
      <ApplyModalContent positionId={applyModalStore.positionId} />
    </Suspense>
  )
})

const ApplyModalContent: React.FC<{ positionId: number }> = observer(
  ({ positionId }) => {
    const { applyModalStore } = useStores()
    const { data: user } = useUser()
    const { data: profile } = useScoutMyRequest()
    const { data: position, error } = usePositionDetail(positionId)
    const { data: temp } = useTempApplication(positionId)
    const store = useRef(new ApplyStore(positionId)).current
    const startup = position?.startup
    const hasProfile =
      !!profile &&
      !!profile.educations.length &&
      (profile.careers.length > 0 || profile.otherActivities.length > 0)

    useEffect(() => {
      if (error?.status === 404) applyModalStore.closeModal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    if (
      profile === undefined ||
      temp === undefined ||
      !startup ||
      !position ||
      !user
    )
      return null
    return (
      <Modal
        width={700}
        onClose={() => applyModalStore.closeModal()}
        disableBackgroundClick
      >
        <Content>
          {store.isDone ? (
            <ApplyDone
              data={position}
              onClose={() => applyModalStore.closeModal()}
            />
          ) : (
            <ApplyForm
              user={user}
              data={position}
              temp={temp}
              applyStore={store}
              hasProfile={hasProfile}
            />
          )}
        </Content>
      </Modal>
    )
  },
)

const Content = styled(Column)`
  padding: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 24px;
  }
`
