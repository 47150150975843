import React, { useRef } from 'react'
import styled from 'styled-components'
import { theme } from 'infra/theme'
import { Card } from 'components/common/card'
import { BaseText } from 'components/common/text'
import { Column } from 'components/common/layout'
import { Avatar } from 'components/common/avatar'
import { ManagerComment as ManagerCommentProps } from 'types/common'
import { useRouter } from 'next/router'
import { useDashboardStartupDetail } from 'apis/hooks'

export const ManagerComment: React.FC<{
  managerComment: ManagerCommentProps
  lineClamp?: number
  isNarrow?: boolean
}> = ({ managerComment, lineClamp, isNarrow }) => {
  const { data: startup } = useDashboardStartupDetail()
  const router = useRouter()
  const textRef = useRef<HTMLSpanElement>(null)
  return (
    <CommentCard>
      {!isNarrow && (
        <Column style={{ gap: 8, alignItems: 'center' }}>
          <Avatar size={40} src={managerComment.manager?.image} />
          <Column style={{ gap: 2, textAlign: 'center' }}>
            <NameText>채용매니저</NameText>
            <NameText>{managerComment.manager?.name}</NameText>
          </Column>
        </Column>
      )}
      <Column style={{ flex: 1, gap: 4, justifyContent: 'flex-start' }}>
        <CommentText style={{ color: theme.color.gray2 }}>
          {router.pathname.includes('recommended')
            ? `${startup?.name}에 추천하는 이유`
            : '매니저 코멘트'}
        </CommentText>
        <CommentText ref={textRef} lineClamp={lineClamp}>
          {managerComment.comment}
        </CommentText>
      </Column>
    </CommentCard>
  )
}

const CommentCard = styled(Card)`
  gap: 24px;
  padding: 16px;
  flex-direction: row;
  background-color: ${(p) => p.theme.color.background};
`

const NameText = styled(BaseText)`
  font-size: 12px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 10px;
  }
`

const CommentText = styled(BaseText)<{ lineClamp?: number }>`
  line-height: 24px;
  position: relative;
  display: -webkit-box;
  word-wrap: break-word;
  ${(p) =>
    p.lineClamp &&
    `-webkit-box-orient: vertical;
    -webkit-line-clamp: ${p.lineClamp};
    overflow: hidden;
    text-overflow: ellipsis;`}
  @media ${(p) => p.theme.deviceSize.mobile} {
    line-height: 22px;
    font-size: ${(p) => p.theme.fontSize.small};
  }
`
