import React, { useState } from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { Row } from 'components/common/layout'
import { SemiBoldText } from 'components/common/text'
import { theme } from 'infra/theme'

export enum TooltipType {
  WHITE = 'white',
  PRIMARY = 'primary',
  BLACK = 'black',
}

const TooltipTypeMapping = {
  [TooltipType.WHITE]: {
    borderRadius: '4px',
    backgroundColor: theme.color.white1,
    boxShadow: '2px 4px 8px 0px #eaf1ff, -2px -2px 8px 0px #eaf1ff',
    color: '#00325b',
  },
  [TooltipType.PRIMARY]: {
    borderRadius: '16px',
    backgroundColor: '#f4fff7',
    border: '1px solid #00513a',
    color: '#00513a',
  },
  [TooltipType.BLACK]: {
    borderRadius: '16px',
    backgroundColor: theme.color.black1,
    border: `1px solid ${theme.color.black1}`,
    color: '#5efdc2',
  },
}

export const Tooltip: React.FCC<{
  description: string
  size?: number
  variant?: 'info' | 'help'
  placement?: 'right' | 'topEnd'
  tooltipStyle?: React.CSSProperties
}> = ({
  children,
  description,
  size = 20,
  variant = 'info',
  placement = 'right',
  tooltipStyle,
}) => {
  const GAP = 8
  const _tooltipStyle = {
    left: placement === 'right' ? size + GAP : 0,
    transform:
      placement === 'topEnd' ? `translate(calc(-100% + ${size}px), -100%)` : '',
    ...tooltipStyle,
  }
  return (
    <TooltipWrapper tooltipStyle={_tooltipStyle} description={description}>
      <Image
        src={variant === 'info' ? '/info-circle.svg' : '/help-circle.svg'}
        width={size}
        height={size}
        alt='icon'
        priority
      />
      {children}
    </TooltipWrapper>
  )
}

export const TooltipWrapper: React.FCC<{
  description: string
  variant?: TooltipType
  tooltipStyle?: React.CSSProperties
}> = ({ description, variant = TooltipType.WHITE, tooltipStyle, children }) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <Main
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && description && (
        <TooltipPosition style={tooltipStyle} variant={variant}>
          <TooltipText variant={variant}>{description}</TooltipText>
        </TooltipPosition>
      )}
      {children}
    </Main>
  )
}

export const TooltipContent: React.FCC<{
  variant?: TooltipType
  isTextWrap?: boolean
}> = ({ children, variant = TooltipType.WHITE, isTextWrap }) => (
  <TooltipBox variant={variant}>
    <TooltipText variant={variant} isTextWrap={isTextWrap}>
      {children}
    </TooltipText>
  </TooltipBox>
)

const Main = styled(Row)`
  position: relative;
  gap: 4px;
`

export const TooltipBox = styled(Row)<{ variant: TooltipType }>`
  width: fit-content;
  padding: 4px 8px;
  ${(p) => TooltipTypeMapping[p.variant]};
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
`

const TooltipPosition = styled(TooltipBox)<{ variant: TooltipType }>`
  z-index: 1;
  position: absolute;
`

export const TooltipText = styled(SemiBoldText)<{
  variant: TooltipType
  isTextWrap?: boolean
}>`
  white-space: ${(p) => (p.isTextWrap ? 'pre-wrap' : 'nowrap')};
  font-size: 14px;
  line-height: 1.5;
  color: ${(p) => TooltipTypeMapping[p.variant].color};
`
