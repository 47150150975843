import { putRequest } from 'apis/fetcher'
import { postRequestStartupPositionApplicationTempApply } from 'apis/request'
import { makeAutoObservable } from 'mobx'
import { mutate } from 'swr'
import { TempApplication } from 'types/common'

export class ApplyStore {
  positionId: number
  applicationId: number = 0
  coverLetter: string = ''
  tempDate: Date = new Date()
  isTempSaving: boolean | null = null
  isLoading: boolean = false
  isDone: boolean = false

  constructor(positionId: number) {
    this.positionId = positionId
    makeAutoObservable(this)
  }

  setTemp(temp: TempApplication) {
    this.applicationId = temp.id
    this.coverLetter = temp.coverLetter || ''
  }

  setCoverLetter(text: string) {
    this.coverLetter = text
  }

  setIsTempSaving(isTempSaving: boolean | null) {
    this.isTempSaving = isTempSaving // 임시저장 중
  }

  async createTemp() {
    if (this.isLoading) return
    try {
      this.isLoading = true
      await putRequest(
        `/startup-positions/${this.positionId}/applications/temp`,
        {
          coverLetter: '',
        },
      )
      mutate(`/startup-positions/${this.positionId}/applications/temp`)
      this.isLoading = false
    } catch (e) {
      alert(e)
      this.isLoading = false
    }
  }

  async saveTempCoverLetter() {
    try {
      await putRequest(
        `/startup-positions/${this.positionId}/applications/temp`,
        {
          coverLetter: this.coverLetter,
        },
      )
      this.tempDate = new Date()
      this.setIsTempSaving(false) // 임시저장 완료
      mutate(`/startup-positions/${this.positionId}/applications/temp`)
    } catch (e) {
      alert(e)
      this.setIsTempSaving(null) // 임시저장 실패
    }
  }

  async submit() {
    try {
      this.isLoading = true
      await postRequestStartupPositionApplicationTempApply(this.positionId)
      this.isLoading = false
      this.isDone = true
      mutate(`/startup-positions/${this.positionId}/applications/temp`)
      window.dispatchEvent(new Event('focus')) // 채용공고 목록, 지원현황 목록 등을 상황에 맞게 revalidate하기 위함
    } catch (e) {
      alert(e)
      this.isLoading = false
    }
  }
}
