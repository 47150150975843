import {
  deleteRequest,
  getRequest,
  postFormRequest,
  postRequest,
  putFormRequest,
  putRequest,
} from 'apis/fetcher'
import {
  AdminHireCandidatePutRequestParams,
  AlertSettingsPayload,
  ApplicationsAcceptPayload,
  DashboardChatStatusId,
  DashboardPositionParams,
  DashboardScoutRequestOneQueryParams,
  DashboardScoutRequestSearchFilterParams,
  DashboardTempScoutProposal,
  DesireMeetParams,
  Envelope,
  FileSystemVersion,
  NumberOrNull,
  PrivateInfoDetectedResponse,
  ScoutProfileDraftType,
  ScoutRequestParams,
  VerifiedCareer,
} from 'types/common'
import qs from 'query-string'
import { UserAccount } from 'types/common'
import { analytics } from 'infra/analytics'
import moment from 'moment'

export const postRequestAdminCurationResolveTalentRecommend = (params: {
  curationIds: number[]
}) =>
  postRequest(`/admin/per-startup-curations/resolve-talent-recommend`, params)

export const postRequestAdminHireConfirm = (id: number) =>
  postRequest(`/admin/hires/${id}/confirm`)

export const postRequestAdminManualMatches = (params: {
  userId: number
  startupId: number
}) => postRequest(`/admin/manual-matches`, params)

export const postRequestScoutProposalAllowResume = (
  proposalId: number,
  params: { isResumeAllowed: boolean },
) => postRequest(`/scouts/proposals/${proposalId}/allow-resume`, params)

export const postRequestScoutProposalView = (id: number) =>
  postRequest(`/scouts/proposals/${id}/view`)

export const postRequestDashboardScoutProposalProfileFillUp = (id: number) =>
  postRequest(`/dashboard/scouts/proposals/${id}/request-profile-fill-up`)

export const postRequestDashboardScoutProposalHire = (
  params: { proposalId: number; workStartDate: string | null }[],
) => postRequest(`/dashboard/scouts/proposals/hire`, params)

export const postRequestDashboardScoutProposalCancel = (
  id: number,
  params: { cancelMessage: string },
) => postRequest(`/dashboard/scouts/proposals/${id}/cancel`, params)

export const postRequestScoutProposalReject = (
  id: number,
  params: { rejectMessage: string },
) => postRequest(`/scouts/proposals/${id}/reject`, params)

export const postRequestDashboardApplicationAccept = (
  params: ApplicationsAcceptPayload[],
) => postRequest(`/dashboard/applications/accept`, params)

export const postRequestDashboardApplicationReject = (
  id: number,
  params: { rejectMessage: string | null },
) => postRequest(`/dashboard/applications/${id}/reject`, params)

export const postRequestDashboardAccountMe = (params: {
  agreedHireNotification?: boolean
  agreedPenaltyForHireAfterRejection?: boolean
  agreedPenaltyWhenIntentionalHiding?: boolean
  agreedPenaltyForHireAfterCancellation?: boolean
}) => postRequest('/dashboard/accounts/me', params)

export const postRequestDashboardScoutInterestedInMeView = () =>
  postRequest('/dashboard/scouts/requests/interested-in-me/view')

export const postRequestDashboardScoutCurationRequestUninterestedFeedback = (
  curationId: number,
  requestId: number,
  params: { value: string },
) =>
  postRequest(
    `/dashboard/scouts/curations/${curationId}/requests/${requestId}/uninterest-feedback`,
    params,
  )

export const postRequestDashboardScoutTalentRecommendRequest = (params: {
  message?: string
  positionId?: number
}) => postRequest('/dashboard/scouts/talent-recommend-request', params)

export const postRequestDashboardScoutRequestView = (id: number) =>
  postRequest(`/dashboard/scouts/requests/${id}/view`)

export const postRequestDashboardScoutSaveList = (params: { name: string }) =>
  postRequest('/dashboard/scouts/save-list', params)

export const postRequestDashboardStartupRequestInfoFill = (params: {
  type: string
  link: string | null
}) => postRequest(`/dashboard/startup/request-info-fill`, params)

export const postRequestAccountMeStatus = (params: {
  isActive: boolean
  deactivateReason?: string
}) => postRequest('/accounts/me/status', params)

export const postRequestDashboardChatConfirmMeet = (params: {
  proposalId: NumberOrNull
  applicationId: NumberOrNull
  date: string
  isOnline: boolean
  isOffline: boolean
  onlineLink: string
  offlineAddress: string
  meetDesireId?: number
}) => postRequest(`/dashboard/chats/confirm-meet`, params)

export const postRequestScoutProposalDesireMeet = (
  id: number,
  params: DesireMeetParams,
) => postRequest(`/scouts/proposals/${id}/desire-meet`, params)

export const postRequestDashboardScoutProposalProposeMeet = (
  id: number,
  params: { isAddress: boolean },
) => postRequest(`/dashboard/scouts/proposals/${id}/propose-meet`, params)

export const postRequestAccountMeVerifyCareerHistory = (params: {
  name: string
  phone: string
  birth: string
}) =>
  postRequest<VerifiedCareer[]>('/accounts/me/verify-career-history', params)

export const postRequestBlockedCompanies = (params: {
  startupIds: number[]
  companyIds: number[]
}) => postRequest('/scouts/requests/me/blocked-companies', params)

export const deleteRequestBlockedCompanies = (params: {
  startupIds: number[]
  companyIds: number[]
}) => deleteRequest('/scouts/requests/me/blocked-companies', params)

export const putRequestScoutRequestMe = (params: Partial<ScoutRequestParams>) =>
  putRequest('/scouts/requests/me', params).then(
    (res) => res as Envelope<PrivateInfoDetectedResponse>,
  )

export const postRequestDashboardScoutCurationRequestUninterested = (
  curationId: number,
  requestId: number,
) =>
  postRequest(
    `/dashboard/scouts/curations/${curationId}/requests/${requestId}/uninterest`,
  )

export const postRequestDashboardScoutCurationRequestBroking = (
  curationId: number,
  requestId: number,
) =>
  postRequest(
    `/dashboard/scouts/curations/${curationId}/requests/${requestId}/request-broking`,
  )

export const postRequestScoutProposalAccept = (id: number) =>
  postRequest(`/scouts/proposals/${id}/accept`)

export const postRequestChatDismiss = (id: number) =>
  postRequest(`/chat/dismiss?proposalId=${id}`)

export const postRequestScoutRequestMeInfoFill = (params: {
  mainFile: number | null
  files: number[]
  links: number[]
}) => postRequest('/scouts/requests/me/request-info-fill', params)

export const postRequestScoutRequestMeSendBackLink = () =>
  postRequest('/scouts/requests/me/send-back-link')

export const postRequestDashboardScoutProposalRequestResume = (id: number) =>
  postRequest(`/dashboard/scouts/proposals/${id}/request-resume`)

export const postRequestDashboardAcknowledgeHiring = () =>
  postRequest('/dashboard/acknowledge-hiring')

export const postRequestDashboardAccountMeStartupPic = (params: {
  name: string
  position: string
  phone: string
  email: string
}) => postRequest('/dashboard/accounts/me/startup-pic', params)

export const postRequestDashboardAccountMeStartupPicAlert = (
  id: number,
  params: AlertSettingsPayload[],
) => postRequest(`/dashboard/accounts/me/startup-pic/${id}`, params)

interface postRequestDashboardScoutSearchTemplateParams
  extends Omit<DashboardScoutRequestOneQueryParams, 'languageSkills'> {
  templateName: string
  languageSkills: { language: number; level: number | null }[]
}

export const postRequestDashboardScoutSearchTemplate = (
  params: postRequestDashboardScoutSearchTemplateParams,
) => postRequest('/dashboard/scouts/search-templates', params)

export const postRequestAdminAutoLoginLink = (params: {
  startupId: number
  path: string
}) => postRequest<{ link: string }>(`/admin/auto-login-link`, params)

export const postRequestDashboardAccountFindPassword = (params: {
  username: string
}) =>
  postRequest<{ email: string }>('/dashboard/accounts/find-password', params)

export const postRequestDashboardSaveAccessLog = (params: {
  hireStatus: string
  alertLevel: string
}) => postRequest(`/dashboard/save-access-log?${qs.stringify(params)}`)

export const postRequestDashboardScoutCurationView = (id: number) =>
  postRequest(`/dashboard/scouts/curations/${id}/view`)

export const postRequestAccountMeApplicationReportProcessNotNoted = (
  id: number,
) => postRequest(`/accounts/me/applications/${id}/reportProcessNotNoted`)

export const postRequestStartupInterestedInMeView = () =>
  postRequest('/startups/interested-in-me/view')

interface postRequestTestUserParams
  extends Omit<UserAccount, 'positions' | 'techStacks' | 'experience'> {
  experience?: number
  interestedPositionTypeIds?: number[]
  interestedTechStackIds?: number[]
}

export const postRequestTestUser = (params: postRequestTestUserParams) =>
  postRequest('/test/users', params)

export const postRequestAdminCurationScoutRequest = (
  id: number,
  params: { scoutRequestId: number },
) => postRequest(`/admin/per-startup-curations/${id}/scout-requests`, params)

export const postRequestAdminCuration = (params: {
  name: string
  positionId: number
  startupId: number
  managerId: number | null
  isLowPriority: boolean
}) => postRequest(`/admin/per-startup-curations`, params)

export const postRequestStartupPositionApplicationTempApply = (id: number) =>
  postRequest(`/startup-positions/${id}/applications/temp/apply`)

export const postRequestDashboardScoutProposalGenerate = (
  id: number,
  params: { fields: string[]; refresh: boolean },
) =>
  postRequest<{ proposeReason: string; appealPoints: string }>(
    `/dashboard/scouts/requests/${id}/proposal/generate`,
    params,
  )

export const postRequestDashboardApplicationExtend = (id: number) =>
  postRequest(`/dashboard/applications/${id}/extend`)

export const postRequestDashboardApplicationAcceptResume = (
  id: number,
  params: { message: string },
) => postRequest(`/dashboard/applications/${id}/accept-resume`, params)

export const postRequestDashboardApplicationView = (id: number) =>
  postRequest(`/dashboard/applications/${id}/view`)

export const postRequestDashboardAuthCode = (params: { email: string }) =>
  postRequest(`/dashboard/auth-code`, params)

export const postRequestDashboardLoginByEmail = (params: {
  email: string
  authCode: string
}) => postRequest(`/dashboard/login-by-email`, params)

export const postRequestDashboardLogin = (params: {
  username: string
  password: string
}) => postRequest(`/dashboard/login`, params)

export const postRequestDashboardPositions = (
  params: DashboardPositionParams,
) => postRequest<PrivateInfoDetectedResponse>(`/dashboard/positions`, params)

export const putRequestDashboardPositions = (
  positionId: number,
  params: DashboardPositionParams,
) =>
  putRequest<PrivateInfoDetectedResponse>(
    `/dashboard/positions/${positionId}`,
    params,
  )

export const putRequestDashboardStartup = (params: FormData) =>
  putFormRequest<PrivateInfoDetectedResponse>(`/dashboard/startup`, params)

export const postRequestDashboardScoutSaveListRequest = (
  id: number,
  params: { scoutRequestId: number },
) => postRequest(`/dashboard/scouts/save-list/${id}/requests`, params)

export const putRequestDashboardScoutRequestProposal = (
  proposalId: number,
  shouldDetect: boolean,
  params: DashboardTempScoutProposal,
) =>
  putRequest<PrivateInfoDetectedResponse>(
    `/dashboard/scouts/requests/${proposalId}/proposal?shouldDetect=${shouldDetect}`,
    params,
  )

export const postRequestDashboardScoutRequestProposalFinalize = (id: number) =>
  postRequest<{ proposalId: number }>(
    `/dashboard/scouts/requests/${id}/proposal/finalize`,
  )

export const postRequestAccountMeStart = (params: {
  name: string
  phone: string
  email: string
  experience: number
  acquisitionFunnel: string | null
  recommenderPhone: string | null
  interestedPositionTypes: number[]
  interestedTechStacks: number[]
  tempToken: string
}) => postRequest('/accounts/me/start', params)

export const postRequestStartupPositionView = (id: number) =>
  postRequest(`/startup-positions/${id}/view`)

export const postRequestStartupView = (id: number) =>
  postRequest(`/startups/${id}/view`)

export const postRequestAnalyticsStartupScoutSearchSessions = () =>
  postRequest<{ sessionId: number }>(
    '/analytics/startups/scout-search-sessions',
  )

export const postRequestAnalyticsStartupScoutSearchSessionEvents = (params: {
  sessionId: number
  type: 'SAVE' | 'PROPOSE' | 'INPUT_CHANGE'
  data?: string
}) => postRequest('/analytics/startups/scout-search-sessions/events', params)

export const postAdminHireTrackingStartupMemo = (
  startupId: number,
  params: { memo: string },
) => postRequest(`/admin/hires/tracking/startups/${startupId}`, params)

export const putAdminHireTrackingStartupInfo = (
  startupId: number,
  params: { isHide?: boolean; isSelfReported?: boolean },
) => putRequest(`/admin/hires/tracking/startups/${startupId}`, params)

export const putAdminHireCandidate = (
  params: AdminHireCandidatePutRequestParams,
) => putRequest('/admin/hire-candidates/one', params)

export const putAdminManualMatchMemo = (
  manualMatchId: number,
  params: { memo: string },
) => putRequest(`/admin/manual-matches/${manualMatchId}`, params)

export const putAdminHireTrackingUserMemo = (
  userId: number,
  params: { hireTrackingMemo: string },
) => putRequest(`/admin/hires/tracking/users/${userId}/save-memo`, params)

export const postDashboardScoutCurationRequestViewLink = (
  curationId: number,
  requestId: number,
) =>
  postRequest(
    `/dashboard/scouts/curations/${curationId}/requests/${requestId}/view-link`,
  )

export const postScoutsRequestsMeRejectInfoFill = (params: {
  value: string
  infoFillRequestId: NumberOrNull
}) => postRequest(`/scouts/requests/me/reject-info-fill`, params)

export const putScoutsRequestsMeIsPublished = (params: { value: boolean }) =>
  putRequest(`/scouts/requests/me/is-published`, params).then(() => {
    params.value &&
      analytics.setIdentify({ profileOpenDate: moment().format('YYMMDD') })
  })

export const putAdminHireTrackingChatsStep = (params: {
  proposalId?: number
  applicationId?: number
  chatStep: DashboardChatStatusId
}) => putRequest('/admin/hires/tracking/chats/step', params)

export const postDashboardScoutsProposalBrokingScoutProposal = (
  proposalId: number,
  params: { perStartupCurationScoutRequestId: number; comment: string },
) =>
  postRequest(
    `/dashboard/scouts/proposals/${proposalId}/broking-scout-proposal`,
    params,
  )

export const postScoutsProposalAcceptBrokingScoutProposal = (
  proposalId: number,
  params: { perStartupCurationScoutRequestId: number },
) =>
  postRequest(
    `/scouts/proposals/${proposalId}/accept-broking-scout-proposal`,
    params,
  )

export const postScoutsProposalRejectBrokingScoutProposal = (
  proposalId: number,
  params: { perStartupCurationScoutRequestId: number; rejectReason: string },
) =>
  postRequest(
    `/scouts/proposals/${proposalId}/reject-broking-scout-proposal`,
    params,
  )

export const postDashboardScoutsProposalAcceptBrokingScoutProposal = (
  proposalId: number,
  params: { perStartupCurationScoutRequestId: number },
) =>
  postRequest(
    `/dashboard/scouts/proposals/${proposalId}/accept-broking-scout-proposal`,
    params,
  )

export const postDashboardScoutsProposalRejectBrokingScoutProposal = (
  proposalId: number,
  params: { perStartupCurationScoutRequestId: number },
) =>
  postRequest(
    `/dashboard/scouts/proposals/${proposalId}/reject-broking-scout-proposal`,
    params,
  )

export const getDashboardPositionToText = (positionId: number) =>
  getRequest(`/dashboard/positions/${positionId}/text`).then(
    ({ data }) => data as { value: string },
  )

export const postDashboardScoutTextToSearches = (
  text: string,
  params: DashboardScoutRequestOneQueryParams,
) =>
  postRequest<{
    explain: string
    search: DashboardScoutRequestSearchFilterParams
  }>(`/dashboard/scouts/text-to-searches`, {
    text,
    search: params,
  })

export const postScoutsRequestsMeRequestInfoFillDraftsClose = (
  category: ScoutProfileDraftType,
) =>
  postRequest('/scouts/requests/me/request-info-fill/drafts/close', {
    category,
  })

export const postAdminFileAnonymized = (
  fileId: number,
  fileSystemVersion: FileSystemVersion,
  formData: FormData,
) =>
  postFormRequest(
    `/admin/files/${fileId}/anonymized?fileSystemVersion=${fileSystemVersion}`,
    formData,
  )

export const deleteAdminFileAnonymized = (
  fileId: number,
  fileSystemVersion: FileSystemVersion,
) =>
  deleteRequest(
    `/admin/files/${fileId}/delete-file?fileSystemVersion=${fileSystemVersion}`,
  )

export const putAdminFileIsNotForAnonymization = (
  fileId: number,
  fileSystemVersion: FileSystemVersion,
  value: boolean,
) =>
  putRequest(
    `/admin/files/${fileId}/is-not-for-anonymization?fileSystemVersion=${fileSystemVersion}`,
    { value },
  )

export const putAdminFileReview = (
  fileId: number,
  fileSystemVersion: FileSystemVersion,
  value: boolean,
) =>
  putRequest(
    `/admin/files/${fileId}/review?fileSystemVersion=${fileSystemVersion}`,
    { value },
  )
