import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  BaseText,
  BoldText,
  DescText,
  MediumText,
  SemiBoldText,
} from 'components/common/text'
import { theme } from 'infra/theme'
import { MyLink } from 'components/common/link'
import { UserInformation } from 'components/positions/user-information'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { Column, Row } from 'components/common/layout'
import { CoverLetter } from 'components/apply/cover-letter'
import { InformationBox } from 'components/misc/information-box'
import { ApplyStore } from 'stores/apply'
import { StartupPositionDetail, TempApplication, User } from 'types/common'
import { UserStore } from 'stores/user'
import { observer } from 'mobx-react'
import { FiCheck, FiChevronRight } from 'react-icons/fi'
import { analytics } from 'infra/analytics'
import { useIsDesktop } from 'utils/hooks'
import Bugsnag from '@bugsnag/js'
import { REAPPLY_ALERT } from 'infra/constants'

export const ApplyForm: React.FC<{
  user: User
  data: StartupPositionDetail
  temp: TempApplication | null
  applyStore: ApplyStore
  hasProfile: boolean
}> = observer(({ user, data, temp, applyStore, hasProfile }) => {
  const isDesktop = useIsDesktop()
  const userStore = useRef(new UserStore(user)).current
  useEffect(() => {
    if (temp?.id && !applyStore.applicationId) applyStore.setTemp(temp)
    if (temp === null) applyStore.createTemp()
  }, [temp, applyStore])
  return (
    <ContentBox>
      <PageTitle>{`${data.startup.name}의\n${data.name} · ${data.careerType}에 지원하기`}</PageTitle>
      <Section>
        <Row style={{ justifyContent: 'space-between' }}>
          <SectionHeader>
            연락처 <PrimaryText>*</PrimaryText>
          </SectionHeader>
          {userStore.isSaved && (
            <Row style={{ gap: 4 }}>
              <FiCheck size={24} strokeWidth={2} color={theme.color.primary} />
              <BaseText>저장됨</BaseText>
            </Row>
          )}
        </Row>
        <UserInformation store={userStore} isAutosave />
      </Section>
      <ResponsiveBox>
        <Row style={{ gap: 16 }}>
          <SectionHeader>
            내 프로필 <PrimaryText>*</PrimaryText>
          </SectionHeader>
          <MyLink
            href={
              hasProfile
                ? '/scouts/my-profile'
                : '/scouts/request-form/job-search-status'
            }
            newTab
          >
            <Row>
              <DescText style={{ color: theme.color.primary }}>
                {hasProfile ? '프로필 확인하기' : '프로필 준비하기'}
              </DescText>
              <FiChevronRight size={18} color={theme.color.primary} />
            </Row>
          </MyLink>
        </Row>
      </ResponsiveBox>
      <Section>
        <SectionHeader>지원 메시지</SectionHeader>
        <CoverLetter store={applyStore} startupName={data.startup.name} />
      </Section>
      <Section>
        {hasProfile && (
          <InformationBox>
            내 프로필은{' '}
            <span
              style={{ fontWeight: theme.fontWeight.bold }}
            >{`마이페이지 > 내 프로필`}</span>{' '}
            에서 언제든 수정할 수 있어요.
          </InformationBox>
        )}
        <Column style={{ gap: 8 }}>
          <Button
            size={isDesktop ? undefined : BtnSize.MEDIUM}
            variant={hasProfile ? BtnType.PRIMARY : BtnType.DISABLE}
            onClick={() => {
              if (!data.userApplication?.isPossibleApply) {
                Bugsnag.notify('지원 모달인데 지원 불가') // 발생하면 안 되는데 혹시나
                return alert(
                  '지원 가능 횟수를 초과하여 해당 공고에 지원할 수 없어요.',
                )
              }
              if (applyStore.isTempSaving || applyStore.isLoading) return
              if (
                !hasProfile ||
                !window.confirm(
                  data.userApplication.lastApplication
                    ? REAPPLY_ALERT
                    : `${data.startup.name}의 ${data.name} ‧ ${data.careerType}에 지원하시겠습니까?`,
                )
              )
                return
              applyStore.submit()
              analytics.track('click_ap_apply_btn', {
                startupId: data.startup.id,
                positionId: data.id,
                startupName: data.startup.name,
                positionName: data.name,
              })
            }}
          >
            {data.userApplication?.lastApplication
              ? '다시 지원하기'
              : '지원하기'}
          </Button>
          {!hasProfile && (
            <AlertText>
              {`지원하려면 프로필이 필요해요\n(필수 입력 정보만 완료하면 돼요)`}
            </AlertText>
          )}
        </Column>
      </Section>
    </ContentBox>
  )
})

const ContentBox = styled(Column)`
  gap: 56px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 48px;
  }
`

const PageTitle = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h3};
  line-height: 1.4;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

const Section = styled(Column)`
  gap: 16px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 8px;
  }
`

const SectionHeader = styled(BoldText)`
  font-size: ${(p) => p.theme.fontSize.h4};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const PrimaryText = styled.span`
  color: ${(p) => p.theme.color.primary};
`

const ResponsiveBox = styled(Row)`
  justify-content: space-between;
  @media ${(p) => p.theme.deviceSize.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`

const AlertText = styled(MediumText)`
  font-size: ${(p) => p.theme.fontSize.small};
  color: #bebeb9;
  text-align: center;
  @media ${(p) => p.theme.deviceSize.desktop} {
    white-space: nowrap;
  }
`
