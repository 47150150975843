// TODO(son) 파일 리팩토링 proposal-detail 파일이랑 겹침
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import {
  BaseText,
  DescText,
  H4Text,
  H5Text,
  SemiBoldText,
  SmallText,
} from 'components/common/text'
import { StartupLogo } from 'components/startup/startup-logo'
import { OutlinedCard } from 'components/common/card'
import { StartupTableInfo } from 'components/startup/startup-table-info'
import { TextViewer } from 'components/common/text-editor'
import { TeamMember } from 'components/startup/team-member'
import { StartupImages } from 'components/startup/startup-images'
import { FiCheck, FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { theme } from 'infra/theme'
import { Tag, TagContainer } from 'components/common/tag'
import {
  getConditions,
  getConditionSatisfactionPercentage,
  getFlattenedData,
  getSelectedChildrenByParent,
} from 'utils/formatters'
import { getDueDateMessage } from 'utils/displays'
import { displayPositionName } from 'utils/displays'
import { getProposalMessage } from 'utils/displays'
import {
  Condition,
  DashboardScoutRequestDetail,
  DashboardTempScoutProposal,
  Range,
  ScoutFulfillment,
  ScoutPosition,
  ScoutProposalDetail,
  StartupDetailForProposal,
} from 'types/common'
import { observer } from 'mobx-react'
import { MyLink } from 'components/common/link'
import { useRouter } from 'next/router'
import { useIsDesktop, useIsLoggedInAdmin } from 'utils/hooks'
import { useStores } from 'stores/globals'
import { Notice } from 'components/scouts/request-form/notice'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { LineLimitedText } from 'components/scouts/profile-detail/desc-more-view'
import { Card } from 'components/common/card'
import { useBaseInfo } from 'apis/hooks'
import { Badge, BadgeColor } from 'components/common/badge'
import { DescBox } from 'components/scouts/chat/styles'

const ConditionList: React.FC<{
  conditions: Condition[]
  fulfillments: ScoutFulfillment[]
}> = ({ conditions, fulfillments }) => {
  return (
    <>
      {conditions.map((condition) => {
        const isRequired = fulfillments
          .map((item) => item.dataKey)
          .includes(condition.dataKey)
        const description = fulfillments.find(
          (item) => item.dataKey === condition.dataKey,
        )?.description
        return (
          <Column key={condition.dataKey} style={{ gap: 4 }}>
            <Row style={{ gap: 10, alignItems: 'center' }}>
              <FiCheck
                size={24}
                color={isRequired ? theme.color.primary : theme.color.gray3}
                strokeWidth={3}
              />
              <BaseText style={{ flex: 1 }}>{condition.value}</BaseText>
            </Row>
            {description && (
              <DescBox
                style={{
                  padding: '4px 8px',
                  marginLeft: 30,
                  backgroundColor: '#eeeeee',
                }}
              >
                <SmallText style={{ color: '#999999' }}>
                  {description}
                </SmallText>
              </DescBox>
            )}
          </Column>
        )
      })}
    </>
  )
}

const ConditionSatisfaction: React.FC<{
  requireConditions: Condition[]
  normalConditions: Condition[]
  fulfillments: ScoutFulfillment[]
}> = ({ requireConditions, normalConditions, fulfillments }) => {
  return (
    <>
      <Column style={{ gap: 8 }}>
        <Badge
          color={BadgeColor.SECONDARY}
          filled
          style={{ width: 'fit-content' }}
        >
          중요
        </Badge>
        <ConditionList
          conditions={requireConditions}
          fulfillments={fulfillments}
        />
      </Column>
      <Column style={{ gap: 8 }}>
        <Badge
          color={BadgeColor.SECONDARY}
          filled
          style={{ width: 'fit-content' }}
        >
          선호
        </Badge>
        <ConditionList
          conditions={normalConditions}
          fulfillments={fulfillments}
        />
      </Column>
    </>
  )
}

const ConditionSection: React.FC<{
  request: DashboardScoutRequestDetail
  proposal: DashboardTempScoutProposal | ScoutProposalDetail
}> = observer(({ request, proposal }) => {
  const { data: baseInfo } = useBaseInfo()

  const selectedChildrenByParent = useMemo(
    () =>
      getSelectedChildrenByParent(
        baseInfo?.locations || [],
        request.locations || [],
      ),
    [baseInfo?.locations, request.locations],
  )

  const flattenedLocations = useMemo(
    () => getFlattenedData(baseInfo?.locations || [], selectedChildrenByParent),
    [baseInfo?.locations, selectedChildrenByParent],
  )

  const requireConditions = getConditions({
    profile: {
      ...request,
      locations: flattenedLocations,
    },
    isRequired: true,
  })
  const normalConditions = getConditions({
    profile: {
      ...request,
      locations: flattenedLocations,
    },
    isRequired: false,
  })

  return (
    <Section>
      <Center>
        <Header>
          내 조건에{' '}
          <strong style={{ color: theme.color.primary }}>
            {getConditionSatisfactionPercentage(
              [...requireConditions, ...normalConditions],
              proposal.fulfillments,
            )}
            %
          </strong>{' '}
          만족해요
        </Header>
      </Center>
      <StartupInfoCard style={{ borderRadius: 8 }}>
        <ConditionSatisfaction
          normalConditions={normalConditions}
          requireConditions={requireConditions}
          fulfillments={proposal.fulfillments}
        />
      </StartupInfoCard>
    </Section>
  )
})

export const DashboardProposalDetail: React.FC<{
  startup: StartupDetailForProposal
  request: DashboardScoutRequestDetail
  proposal: DashboardTempScoutProposal | ScoutProposalDetail
  proposedPositions: Array<ScoutPosition & { experienceRange?: Range | null }>
  isPublished?: boolean // 기업 상세정보 공개 유무 (기업에서만 받음)
  isStartup?: boolean // 기업 사이드인지
  isExample?: boolean // greeting 페이지에서 가짜 데이터로 보여주는 상황인지
}> = observer(
  ({
    startup,
    request,
    proposal,
    proposedPositions,
    isPublished,
    isStartup,
    isExample,
  }) => {
    const [isDetail, setIsDetail] = useState(false)
    const router = useRouter()
    const isAdmin = useIsLoggedInAdmin()
    const isDesktop = useIsDesktop()
    const { positionModalStore } = useStores()
    const proposalMessage =
      'introPerson' in proposal
        ? getProposalMessage(proposal)
        : proposal.message
    const startupLogoThumbnail = startup.thumbnail || '/no-startup-logo.svg'
    return (
      <Container>
        <Section>
          <HeaderWrapper>
            <StartupLogo src={startupLogoThumbnail} size={56} />
            <Header>
              <strong>{startup.name}</strong>에서 <br />
              새로운 메시지가 도착했어요
            </Header>
            {!isExample && !isStartup && (
              <Notice>
                {getDueDateMessage(
                  'dueDate' in proposal ? proposal.dueDate : undefined,
                )}
              </Notice>
            )}
          </HeaderWrapper>
          <OutlinedCard style={{ padding: 16, gap: 24 }}>
            {isExample ? (
              <LineLimitedText text={proposalMessage} lineClamp={6} />
            ) : (
              <MessageText>
                {proposalMessage.replaceAll('#{인재이름}', request.name)}
              </MessageText>
            )}
          </OutlinedCard>
          {isPublished !== null && !isPublished && isStartup && (
            <PublishNoticeText>
              아직 상세페이지가 작성되지 않았어요. 상세페이지 작성 후 실제로
              메시지가 보내질 때는 아래 내용이 제대로 입력돼요.
            </PublishNoticeText>
          )}
          {!isAdmin && <StartupTableInfo data={startup} />}
        </Section>
        {proposedPositions.length > 0 && (
          <Section>
            <Center>
              <Header>{request.name}님에게 맞는 포지션</Header>
            </Center>
            <Grid>
              {proposedPositions.map((position) => (
                <PositionCard
                  key={position.id}
                  style={{ gap: 8, cursor: 'pointer' }}
                  onClick={() => {
                    isDesktop
                      ? positionModalStore.openModal(position.id)
                      : router.push(`/positions/${position.id}`)
                  }}
                >
                  <SemiBoldText style={{ fontSize: 16 }}>
                    {displayPositionName(
                      position.name,
                      position.careerType,
                      position.experienceRange || null,
                    )}
                  </SemiBoldText>
                  <TagContainer
                    style={{
                      flex: 1,
                      alignContent: 'flex-start',
                      maxHeight: 66,
                      overflow: 'hidden',
                    }}
                  >
                    {position.techStacks.map((tech, index) => (
                      <Tag key={index}>{tech}</Tag>
                    ))}
                  </TagContainer>
                </PositionCard>
              ))}
            </Grid>
          </Section>
        )}
        <ConditionSection proposal={proposal} request={request} />
        <Section>
          <Center>
            <Header>
              <strong>{startup.name}</strong>에 대해 알려드릴게요
            </Header>
          </Center>
          <StartupInfoCard>
            <StartupInfoTitle>어떤 회사인가요?</StartupInfoTitle>
            <TextViewer value={startup.whatKindCompany} />
          </StartupInfoCard>
          {isDetail ? (
            <>
              {startup.curOutcome && (
                <StartupInfoCard>
                  <StartupInfoTitle>현재까지 성과</StartupInfoTitle>
                  <TextViewer value={startup.curOutcome} />
                </StartupInfoCard>
              )}
              <StartupInfoCard>
                <StartupInfoTitle>팀</StartupInfoTitle>
                {startup.members.map((member) => (
                  <TeamMember
                    key={member.id}
                    data={member}
                    backgroundColor='#FAFAFA'
                  />
                ))}
              </StartupInfoCard>
              <StartupImages images={startup.images} width={718} />
              {startup.reasonForInvesting && (
                <StartupInfoCard>
                  <StartupInfoTitle>투자 받은 이유</StartupInfoTitle>
                  <TextViewer value={startup.reasonForInvesting} />
                </StartupInfoCard>
              )}
            </>
          ) : (
            <OutlinedCard
              style={{ padding: 8, cursor: 'pointer' }}
              onClick={() => setIsDetail(true)}
            >
              <Row style={{ gap: 4, justifyContent: 'center' }}>
                <H5Text style={{ color: theme.color.black4 }}>
                  상세정보 더보기
                </H5Text>
                <FiChevronDown size={24} color={theme.color.black4} />
              </Row>
            </OutlinedCard>
          )}
        </Section>
        {isDetail && !isExample && (
          <Center>
            <MyLink href={`/startups/${startup.id}`} newTab>
              <Button
                variant={BtnType.PRIMARY2}
                size={isDesktop ? BtnSize.LARGE : BtnSize.MEDIUM}
                style={{ width: 'fit-content' }}
              >
                <Row style={{ gap: 10, justifyContent: 'center' }}>
                  <StartupLogo src={startupLogoThumbnail} size={32} />
                  <BtnText>{startup.name}에 대해 더 알아보기</BtnText>
                  <FiChevronRight />
                </Row>
              </Button>
            </MyLink>
          </Center>
        )}
      </Container>
    )
  },
)

const PublishNoticeText = styled(H5Text)`
  padding: 40px 0;
  text-align: center;
  color: ${(p) => p.theme.color.primary};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
    padding: 24px 0;
    line-height: 24px;
  }
`

const Container = styled(Column)`
  gap: 64px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 48px;
  }
`

const BtnText = styled.span`
  font-size: ${(p) => p.theme.fontSize.desc};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

const PositionCard = styled(Card)`
  padding: 16px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.background2};
  :hover {
    background: ${(p) =>
      `linear-gradient(${p.theme.color.white1}52, ${p.theme.color.white1}52), ${p.theme.color.background2}`};
  }
`

const Section = styled(Column)`
  gap: 16px;
`

const Center = styled(Section)`
  align-items: center;
`

const HeaderWrapper = styled(Center)`
  gap: 16px;
  padding-bottom: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding-bottom: 8px;
  }
`

const Header = styled(H4Text)`
  text-align: center;
  line-height: 1.5;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    br {
      display: none;
    }
  }
`

const MessageText = styled(DescText)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: #555555;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
    line-height: 25px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    grid-template-columns: 1fr;
  }
`

const StartupInfoCard = styled(OutlinedCard)`
  gap: 16px;
  border-radius: 16px;
  padding: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
  }
`

const StartupInfoTitle = styled(SemiBoldText)`
  font-size: 20px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`
