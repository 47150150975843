import React from 'react'
import {
  SectionDesc,
  SectionPaper,
  SectionTitle,
} from 'components/startup/startup-styles'
import { Column } from 'components/common/layout'
import { isEndWithConsonant } from 'utils/validations'
import { TextViewer } from 'components/common/text-editor'
import { TEXT_EDITOR_DEFAULT_VALUE } from 'infra/constants'

export const ReasonForInvesting: React.FC<{
  name: string
  reasonForInvesting: string
}> = ({ name, reasonForInvesting }) => {
  if (!reasonForInvesting || reasonForInvesting === TEXT_EDITOR_DEFAULT_VALUE)
    return null
  return (
    <Column id='reasonForInvesting'>
      <SectionTitle>투자받은 이유</SectionTitle>
      <SectionDesc>
        {`투자사의 투자 이유를 통해 ${name}${
          isEndWithConsonant(name) ? '이' : '가'
        } 앞으로 어떻게 성장할지 파악해보세요.`}
      </SectionDesc>
      <SectionPaper>
        <TextViewer value={reasonForInvesting} />
      </SectionPaper>
    </Column>
  )
}
