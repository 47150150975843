import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import Image from 'next/legacy/image'
import { SmallText } from 'components/common/text'

export enum CheckBoxSize {
  DEFAULT = 18,
  LARGE = 24,
} // 체크박스 사이즈

export enum CheckBoxType {
  DEFAULT = 2,
  ROUND = 100,
} // 체크 아이콘 border radius

export const CheckBox: React.FCC<{
  checked: boolean
  onClick: () => void
  color?: string
  size?: CheckBoxSize
  type?: CheckBoxType
}> = ({
  checked,
  onClick,
  children,
  color,
  size = CheckBoxSize.DEFAULT,
  type = CheckBoxType.DEFAULT,
}) => {
  return (
    <Main onClick={onClick}>
      <IconBox isChecked={checked} color={color} size={size} type={type}>
        {checked ? (
          <Image
            src='/check-white1.svg'
            height={size}
            width={size}
            alt='checked'
          />
        ) : (
          <Image src='/check.svg' height={size} width={size} alt='unchecked' />
        )}
      </IconBox>
      {children && <SubText>{children}</SubText>}
    </Main>
  )
}

const Main = styled(Row)`
  gap: 8px;
  flex: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
`

const IconBox = styled(Row)<{
  isChecked: boolean
  size: CheckBoxSize
  type: CheckBoxType
  color?: string
}>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-radius: ${(p) => p.type}px;
  background-color: ${(p) =>
    p.isChecked ? p.color || p.theme.color.secondary : p.theme.color.white1};
  border: 1px solid
    ${(p) =>
      p.isChecked ? p.color || p.theme.color.secondary : p.theme.color.gray2};
`

const SubText = styled(SmallText)`
  color: ${(p) => p.theme.color.black2};
`
