import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BoldText, MediumText } from 'components/common/text'
import { getWorkLevelExperienceFormat } from 'utils/displays'
import {
  ActivelySeekerBadge,
  CommonCurationBadge,
  IsWorkingBadge,
  JobInterestLevelBadge,
} from 'components/scouts/common/user-base-info-badge'
import { Avatar } from 'components/common/avatar'
import {
  CareerHistory,
  DashboardScoutRequestOneQueryParams,
  WithIdName,
} from 'types/common'
import { useBaseInfo } from 'apis/hooks'
import { CertificateIconSlider } from 'components/certificate/common/certificate-icon-slider'
import { BirthYearTag } from 'components/scouts/common/user-info-tag'
import { DEFAULT_MAX_EXPERIENCE, DEFAULT_MIN_EXPERIENCE } from 'infra/constants'

interface UserBaseInfoProps {
  id: number
  image: string | File
  name: string
  positions: WithIdName[]
  experience: number
  workLevel: WithIdName
  jobInterestLevel: number | null
  isActivelySeeker: boolean
  isCommonCurated: boolean
  idpCertificates: number[]
  careers: CareerHistory[]
  birthYear: string | null
}

export const UserBaseInfo: React.FC<{
  data: UserBaseInfoProps
  isNarrow?: boolean
  params?: DashboardScoutRequestOneQueryParams
}> = ({ data, isNarrow, params }) => {
  const { data: baseInfo } = useBaseInfo()
  const workLevelExperience = getWorkLevelExperienceFormat(
    data.workLevel.id,
    data.experience,
  )
  const isWorking = data.careers.find((item) => item.isWorking)
  const isWorkLevelExperienceHighlighted = params
    ? params.minExperience === DEFAULT_MIN_EXPERIENCE &&
      params.maxExperience === DEFAULT_MAX_EXPERIENCE
      ? params.workLevels.length > 0
      : data.experience >= params.minExperience &&
        data.experience <= params.maxExperience
    : false

  return (
    <UserInfoWrapper isNarrow={isNarrow}>
      <UserContainer>
        <Avatar
          src={
            data.image
              ? typeof data.image === 'string'
                ? data.image
                : URL.createObjectURL(data.image)
              : ''
          }
        />
        <UserTextContainer>
          <FlexWrapRow>
            <UserNameText>{data.name}</UserNameText>
            <ExperienceText
              className={isWorkLevelExperienceHighlighted ? 'highlighted' : ''}
            >
              {workLevelExperience}
            </ExperienceText>
            {data.birthYear && <BirthYearTag birthYear={data.birthYear} />}
            <FlexWrapRow>
              <JobInterestLevelBadge jobInterestLevel={data.jobInterestLevel} />
              {data.isActivelySeeker && <ActivelySeekerBadge />}
              {isWorking && <IsWorkingBadge />}
              {data.isCommonCurated && <CommonCurationBadge />}
            </FlexWrapRow>
          </FlexWrapRow>
          <PositionText
            dangerouslySetInnerHTML={{
              __html: data.positions.map((item) => item.name).join(' ‧ '),
            }}
          />
        </UserTextContainer>
      </UserContainer>
      {data.idpCertificates.length > 0 && (
        <CertificateIconSlider
          data={
            baseInfo?.idpCertificates.filter((idpCertificate) =>
              data.idpCertificates.includes(idpCertificate.id),
            ) || []
          }
          params={params}
          requestId={data.id}
          isFullWidth={isNarrow}
        />
      )}
    </UserInfoWrapper>
  )
}

const UserContainer = styled(Row)`
  gap: 16px;
  align-items: flex-start;
`

const UserInfoWrapper = styled(Row)<{ isNarrow?: boolean }>`
  gap: 32px;
  align-items: flex-start;
  justify-content: space-between;
  ${(p) => p.isNarrow && `flex-direction: column;`}
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 16px;
    flex-direction: column;
  }
`

const UserTextContainer = styled(Column)`
  flex: 1;
  gap: 4px;
`

const FlexWrapRow = styled(Row)`
  gap: 4px;
  flex-wrap: wrap;
`

const UserNameText = styled(BoldText)`
  font-size: 20px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const ExperienceText = styled(UserNameText)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
`

const PositionText = styled(MediumText)`
  line-height: 20px;
  font-size: ${(p) => p.theme.fontSize.desc};
  color: ${(p) => p.theme.color.gray2};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`
