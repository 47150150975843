import React from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'

export const Avatar: React.FC<{
  src?: string | null
  size?: number
}> = ({ src, size = 54 }) => (
  <ImageWrapper size={size} hasImage={!!src}>
    <Image
      src={src || '/user.svg'}
      alt='유저아이콘'
      width={size}
      height={size}
      objectFit='cover'
      priority
    />
  </ImageWrapper>
)

const ImageWrapper = styled.div<{
  size: number
  hasImage: boolean
}>`
  flex: none;
  overflow: hidden;
  border-radius: 100px;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  ${(p) => p.hasImage && `border: 1px solid ${p.theme.color.line};`}
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: ${(p) => p.size - 4}px;
    height: ${(p) => p.size - 4}px;
  }
`
