import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { FiCheck } from 'react-icons/fi'
import { theme } from 'infra/theme'
import { H5Text } from 'components/common/text'
import { AiOutlineClose } from 'react-icons/ai'
import { textEllipsis } from 'components/common/styles'
import { RadioIcon } from './icon'

export enum ChipType {
  FILLED = 'filled',
  FILLED_PRIMARY = 'filled-primary',
  OUTLINED = 'outlined',
  SUPER_ROW = 'super-row',
}

export enum ChipSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  DEFAULT = 'default',
}

const ChipTypeMapping = {
  [ChipType.FILLED]: {
    border: theme.color.black1,
    background: theme.color.primary3,
    color: theme.color.black1,
    hover: `linear-gradient(#80859D14, #80859D14),${theme.color.primary3}`,
    active: `linear-gradient(#80859D1F, #80859D1F),${theme.color.primary3}`,
  },
  [ChipType.FILLED_PRIMARY]: {
    border: theme.color.primary,
    background: theme.color.primary,
    color: theme.color.white1,
    hover: `linear-gradient(#FFFFFF1A, #FFFFFF1A), ${theme.color.primary}`,
    active: `linear-gradient(#FFFFFF38, #FFFFFF38), ${theme.color.primary}`,
  },
  [ChipType.OUTLINED]: {
    border: theme.color.primary,
    background: theme.color.white1,
    color: theme.color.primary,
    hover: `linear-gradient(#24D59E0A, #24D59E0A), ${theme.color.white1}`,
    active: `linear-gradient(#24D59E14, #24D59E14), ${theme.color.white1}`,
  },
  [ChipType.SUPER_ROW]: {
    border: '#F0F0F0',
    background: '#F0F0F0',
    color: theme.color.black3,
    hover: `linear-gradient(#AAAAAA1C, #AAAAAA1C), #F0F0F0`,
    active: `linear-gradient(#AAAAAA33, #AAAAAA33), #F0F0F0`,
  },
}

const ChipSizeMapping = {
  [ChipSize.EXTRA_SMALL]: {
    padding: '4px 8px',
    fontSize: '13px',
  },
  [ChipSize.SMALL]: {
    padding: '6px 12px',
    fontSize: '15px',
  },
  [ChipSize.DEFAULT]: {
    padding: '8px 16px',
    fontSize: '18px',
  },
}

const mapMobileChipSize = (chipSize: ChipSize) => {
  if (chipSize === ChipSize.DEFAULT) return ChipSize.SMALL
  return ChipSize.EXTRA_SMALL
}

interface ChipProps {
  isSelected: boolean
  variant?: ChipType
  size?: ChipSize
  isFullWidth?: boolean
  style?: CSSProperties
  onClick?: () => void
}

export const Chip: React.FCC<ChipProps> = ({
  children,
  isSelected,
  onClick,
  variant,
  size,
  isFullWidth,
  style,
}) => (
  <ChipContainer
    variant={variant}
    isSelected={isSelected}
    onClick={onClick}
    size={size}
    isFullWidth={isFullWidth}
    style={style}
  >
    <ChipLabel variant={variant} size={size} isSelected={isSelected}>
      {children}
    </ChipLabel>
  </ChipContainer>
)

export const CheckboxChip: React.FCC<ChipProps> = ({
  children,
  isSelected,
  isFullWidth,
  variant,
  size,
  onClick,
}) => (
  <ChipContainer
    style={{ gap: 8 }}
    variant={variant}
    isSelected={isSelected}
    isFullWidth={isFullWidth}
    onClick={onClick}
    size={size}
  >
    <CheckboxIconWrapper isSelected={isSelected} variant={variant}>
      <FiCheck
        size={20}
        color={
          isSelected
            ? variant === ChipType.FILLED_PRIMARY
              ? theme.color.primary
              : theme.color.black4
            : theme.color.gray3
        }
      />
    </CheckboxIconWrapper>
    <ChipLabel variant={variant} isSelected={isSelected} size={size}>
      {children}
    </ChipLabel>
  </ChipContainer>
)

export const DeletableChip: React.FCC<ChipProps> = ({
  children,
  isSelected,
  isFullWidth,
  variant,
  size,
  onClick,
}) => (
  <ChipContainer
    style={{ gap: 8 }}
    variant={variant}
    isSelected={isSelected}
    isFullWidth={isFullWidth}
    onClick={onClick}
    size={size}
  >
    <ChipLabel variant={variant} isSelected={isSelected} size={size}>
      {children}
    </ChipLabel>
    <AiOutlineClose size={size === ChipSize.EXTRA_SMALL ? 12 : 16} />
  </ChipContainer>
)

export const RadioChip: React.FCC<{
  isSelected: boolean
  isFullWidth?: boolean
  onClick?: () => void
}> = ({ children, isSelected, isFullWidth, onClick }) => (
  <RadioChipContainer
    isSelected={isSelected}
    isFullWidth={isFullWidth}
    onClick={onClick}
  >
    <RadioIcon isSelected={isSelected} />
    <RadioLabel isSelected={isSelected}>{children}</RadioLabel>
  </RadioChipContainer>
)

const ChipContainer = styled(Row)<{
  isSelected: boolean
  variant?: ChipType
  size?: ChipSize
  isFullWidth?: boolean
}>`
  flex: none;
  border-radius: 16px;
  max-width: 100%;
  width: ${(p) => (p.isFullWidth ? '100%' : 'fit-content')};
  padding: ${(p) => ChipSizeMapping[p.size || ChipSize.DEFAULT].padding};
  border: 1px solid
    ${(p) =>
      p.isSelected
        ? ChipTypeMapping[p.variant || ChipType.FILLED].border
        : theme.color.line};
  svg {
    color: ${(p) =>
      p.isSelected
        ? ChipTypeMapping[p.variant || ChipType.FILLED].color
        : p.theme.color.black4};
  }
  background-color: ${(p) =>
    p.isSelected
      ? ChipTypeMapping[p.variant || ChipType.FILLED].background
      : p.theme.color.white1};
  :hover {
    background: ${(p) =>
      p.isSelected
        ? ChipTypeMapping[p.variant || ChipType.FILLED].hover
        : `linear-gradient(#E0E0DB29, #E0E0DB29), ${p.theme.color.white1}`};
  }
  :active {
    background: ${(p) =>
      p.isSelected
        ? ChipTypeMapping[p.variant || ChipType.FILLED].active
        : `linear-gradient(#E0E0DB42, #E0E0DB42), ${p.theme.color.white1}`};
  }
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: ${ChipSizeMapping[ChipSize.SMALL].padding};
  }
`

const ChipLabel = styled(H5Text)<{
  isSelected: boolean
  variant?: ChipType
  size?: ChipSize
}>`
  flex: 1;
  ${textEllipsis}
  text-align: center;
  line-height: 1.5;
  color: ${(p) =>
    p.isSelected
      ? ChipTypeMapping[p.variant || ChipType.FILLED].color
      : p.theme.color.black4};
  font-size: ${(p) => ChipSizeMapping[p.size || ChipSize.DEFAULT].fontSize};
  user-select: none;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) =>
      ChipSizeMapping[mapMobileChipSize(p.size || ChipSize.DEFAULT)].fontSize};
  }
`

const CheckboxIconWrapper = styled(Row)<{
  isSelected: boolean
  variant?: ChipType
}>`
  flex: none;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid
    ${(p) =>
      p.isSelected
        ? ChipTypeMapping[p.variant || ChipType.FILLED].color
        : p.theme.color.gray3};
  background-color: ${(p) => p.theme.color.white1};
`

const RadioChipContainer = styled(Row)<{
  isSelected: boolean
  isFullWidth?: boolean
}>`
  gap: 8px;
  width: ${(p) => (p.isFullWidth ? '100%' : 'fit-content')};
  padding: 8px 16px;
  border: 1px solid
    ${(p) => (p.isSelected ? p.theme.color.primary6 : p.theme.color.gray3)};
  border-radius: 12px;
  background-color: ${(p) =>
    p.isSelected ? p.theme.color.primary6 : p.theme.color.white1};
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 6px 12px;
    border-radius: 16px;
  }
`

const RadioLabel = styled(H5Text)<{ isSelected: boolean }>`
  flex: 1;
  text-align: center;
  color: ${(p) =>
    p.isSelected ? p.theme.color.primary : p.theme.color.black4};
  line-height: 27px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`

export const ChipWrapper = styled(Row)`
  flex-wrap: wrap;
  gap: 8px;
`
