import React from 'react'
import {
  SectionDesc,
  SectionPaper,
  SectionTitle,
  SubSectionTitle,
  SubSectionWrapper,
} from 'components/startup/startup-styles'
import { Column } from 'components/common/layout'
import { StartupMember } from 'types/common'
import { TextViewer } from 'components/common/text-editor'
import { TeamMember } from 'components/startup/team-member'
import { TEXT_EDITOR_DEFAULT_VALUE } from 'infra/constants'

export const Team: React.FC<{ culture: string; members: StartupMember[] }> = ({
  culture,
  members,
}) => {
  if (!culture && !members.length) return null
  return (
    <Column id='team'>
      <SectionTitle>팀</SectionTitle>
      <SectionDesc>팀의 분위기를 파악해보세요.</SectionDesc>
      <SectionPaper>
        {culture && culture !== TEXT_EDITOR_DEFAULT_VALUE && (
          <SubSectionWrapper>
            <SubSectionTitle>조직문화</SubSectionTitle>
            <TextViewer value={culture} />
          </SubSectionWrapper>
        )}
        {members.length > 0 && (
          <SubSectionWrapper>
            <SubSectionTitle>팀원 정보</SubSectionTitle>
            <Column style={{ gap: 16 }}>
              {members.map((member) => (
                <TeamMember key={member.id} data={member} />
              ))}
            </Column>
          </SubSectionWrapper>
        )}
      </SectionPaper>
    </Column>
  )
}
