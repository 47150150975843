//텍스트 형식 변환 함수 처리

import moment from 'moment'
import {
  ChatStatus,
  DashboardTempScoutProposal,
  Location,
  OtherActivityHistory,
  Range,
  WithIdName,
  WithIdNameParentId,
} from 'types/common'
import { getDiffFromPast } from 'utils/formatters'
import _ from 'lodash-es'

const today = new Date()

export const displaySalaryNegotiableWithStockOption = (
  isSalaryNegotiableWithStockOption: boolean,
) =>
  isSalaryNegotiableWithStockOption
    ? '스톡옵션으로 연봉 협상 가능'
    : '스톡옵션 원하지만 연봉 수준은 유지'

export const getAge = (birthYear: number) => {
  const nowYear = new Date().getFullYear()
  return nowYear - birthYear + 1
}

export const displayExperienceDesc = (exp: number) => {
  if (exp === 0) return '1년 미만'
  if (exp === 10) return '10년 이상'
  return `${exp}년`
}

export const getApplicationChatStatus = (statusId?: number) => {
  if (statusId === 4) return ChatStatus.RESUME_ACCEPTED
  if (statusId === 2) return ChatStatus.ACCEPTED
  if (statusId === 3) return ChatStatus.REJECTED
  return ChatStatus.CHATTING
}
export const getProposalChatStatus = (
  proposal: {
    isRejected?: boolean
    isCanceled?: boolean
    hasDoneMeet?: boolean
    hasConfirmedMeet?: boolean
  },
  isStartup?: boolean,
) => {
  // 채팅 거절 혹은 기업이 채팅 종료 -> "채팅 종료"
  if (proposal.isRejected || proposal.isCanceled) return ChatStatus.ENDED
  // 제안 후 응답 전 초기값 -> "미응답"
  if (proposal.isRejected === null)
    return isStartup ? ChatStatus.NOT_ACCEPTED : ChatStatus.NOT_RESPONSED

  // "커피챗 완료"
  if (proposal.hasDoneMeet) return ChatStatus.MEET_DONE
  // "커피챗 예정"
  if (proposal.hasConfirmedMeet) return ChatStatus.MEET_CONFIRMED
  // 위 조건에 모두 해당하지 않을 때 -> "채팅 진행중"
  return ChatStatus.CHATTING
}

export function convertHyphenPhoneToNumber(hyphenPhone: string) {
  // 하이픈을 제거한 번호 (숫자가 아닌 문자가 입력되는 것도 방지)
  return hyphenPhone.replace(/[^0-9]/g, '')
}

export function convertNumberToHyphenPhone(value: string) {
  // 휴대폰 자동하이픈 생성
  if (value.length === 10) {
    return value
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{0,2})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
      .replace(/(-{1,2})$/g, '')
  }
  return value
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
    .replace(/(-{1,2})$/g, '')
}

export const formatEducationPeriod = (
  admissionYear: string | null,
  graduationYear: string | null,
) => {
  if (!admissionYear) return ''
  return `${admissionYear}${graduationYear ? ` - ${graduationYear}` : ''}`
}

export const getHashtagText = (text: string) => {
  return `#${text.replaceAll(' ', '_')}`
}

export const lastMessageDateFormat = (date: string) => {
  const now = new Date()
  const curDate = new Date(date)
  const diffTimezone = now.getTime() - curDate.getTime()
  const momentDate = moment(curDate)
  const miMill = 1000 * 60
  const hrMill = miMill * 60
  const dyMill = hrMill * 24
  const yrMill = dyMill * 365
  if (diffTimezone < miMill) return '방금'
  if (diffTimezone < dyMill) return momentDate.format('A hh:mm')
  if (diffTimezone > yrMill || now.getFullYear() !== curDate.getFullYear())
    return momentDate.format('YY.MM.DD')
  return moment(curDate).format('M월 D일')
}

export const getProposalMessage = (proposal: DashboardTempScoutProposal) =>
  `${proposal.introPerson}\n\n${proposal.proposeReason}\n\n${proposal.appealPoints}\n\n${proposal.outro}`

export const getDevCountCondition = (min: number, max: number) => {
  let value = ''
  if (min === 0) {
    if (max === 10) return ''
    value = `개발팀 ${max}명 이하`
  } else {
    value = max === 10 ? `개발팀 ${min}명 이상` : `개발팀 ${min}~${max}명`
  }
  return value
}

export const getMemberCountCondition = (min: number, max: number) => {
  let value = ''
  if (min === 0) {
    if (max === 20) return ''
    value = `전체 ${max}명 이하`
  } else {
    value = max === 20 ? `전체 ${min}명 이상` : `전체 ${min}~${max}명`
  }
  return value
}

export const getSalaryCondition = (min: number, max: number) => {
  if (min === 0 && max === 10000) return `연봉 무관`
  if (min === 0 && max !== 10000) return `연봉 ${max}만원 이하`
  if (min !== 0 && max === 10000)
    return `연봉 ${min === 10000 ? '1억' : `${min}만`}원 ${
      !window.location.pathname.includes('dashboard') ? '이상' : '수준'
    }`
  if (min !== 0 && max !== 10000) return `연봉 ${min}~${max}만원`
  return ''
}

export const getJobInterestLevel = (jobInterestLevel: number | null) => {
  if (jobInterestLevel === 1) return '인턴만 희망'
  if (jobInterestLevel === 2) return '정규직 전환 관심'
  if (jobInterestLevel === 3) return '꼭 정규직 전환 희망'
  if (jobInterestLevel === 11) return '팀장급 희망'
  if (jobInterestLevel === 12) return 'C-Level 희망'
  return ''
}

export const displayFilterValue = (
  selectedData: number[],
  data: WithIdNameParentId[],
) => {
  return selectedData.length > 0 && data.length > 0
    ? selectedData.length ===
      data.filter(
        (item) =>
          item.parentId ===
          data.find((item) => item.id === selectedData[0])?.parentId,
      ).length
      ? data.find(
          (item) =>
            item.id ===
            data.find((item) => item.id === selectedData[0])?.parentId,
        )?.name + ' 전체'
      : data.filter((item) => selectedData.includes(item.id))[0].name
    : ''
}

export const getWorkLevelExperienceFormat = (
  workLevelId: number,
  experience: number,
) => {
  if (workLevelId === 1) return '인턴'
  if (workLevelId === 2) return '신입'
  if (experience === 0) return '1년 미만'
  if (experience === 10) return '10년 이상'
  return experience + '년'
}

export const getFilterDisplayName = (v: WithIdName[]) => {
  if (v.length > 1) return `${v[0].name} 외 ${v.length - 1}`
  return v.length > 0 ? v[0].name : ''
}

export const displayRange = ({
  value,
  min,
  max,
  unit = '',
}: {
  value: Range
  min: number
  max: number
  unit?: string
}) => {
  if (value.min === min && value.max === max) return ''
  if (value.min === min && value.max !== max) return `${value.max}${unit} 이하`
  if (value.max === max && value.min !== min) return `${value.min}${unit} 이상`
  return `${value.min}~${value.max}${unit}`
}

export const displayPositionName = (
  name: string,
  careerType: string,
  experienceRange: Range | null,
) => {
  return `${name} · ${careerType}${
    experienceRange
      ? ` ${formatExperienceRange(experienceRange.min, experienceRange.max)}`
      : ' '
  }`
}

export const formatExperienceRange = (min: number, max: number) => {
  return min === 0 && max === 10
    ? '전체'
    : min === max
    ? max + '년'
    : min === 0 && max === 0
    ? '1년 미만'
    : min === 10 && max === 10
    ? '10년 이상'
    : max === 10 && min !== 0
    ? min + '년 이상'
    : min === 0 && max !== 10
    ? max + '년 이하'
    : `${min}~${max}년`
}

export const displayOtherActivityPeriod = (
  otherActivity: OtherActivityHistory,
) => {
  return `${moment(otherActivity.started).format('YYYY.MM')}${
    otherActivity.ended || otherActivity.isOnGoing
      ? `- ${
          otherActivity.isOnGoing
            ? '현재'
            : moment(otherActivity.ended).format('YYYY.MM')
        } ‧ ${calcPeriod(
          moment(otherActivity.started + '-01 00:00').format(
            'YYYY-MM-DD HH:mm',
          ),
          moment(
            otherActivity.isOnGoing
              ? new Date()
              : otherActivity.ended + '-01 00:00',
          )
            .add(1, 'M')
            .format('YYYY-MM-DD HH:mm'),
        )}`
      : ''
  }`
}

export const displayCareerPeriod = ({
  started,
  ended,
  isWorking,
}: {
  started: string | null
  ended: string | null
  isWorking: boolean
}) => {
  return `${moment(started).format('YYYY.MM')} - ${
    isWorking ? '현재' : moment(ended).format('YYYY.MM')
  } ‧ ${calcPeriod(
    moment(started + '-01 00:00').format('YYYY-MM-DD HH:mm'),
    moment(isWorking ? new Date() : ended + '-01 00:00')
      .add(1, 'M')
      .format('YYYY-MM-DD HH:mm'),
  )}`
}

export const calcPeriod = (startedAt: string, endedAt: string) => {
  const start = new Date(startedAt)
  const end = new Date(endedAt)
  const year = moment(end).diff(start, 'year')
  const month = moment(end).diff(start, 'month')
  const diffMonth = month - year * 12
  if (year === 0) return `${month === 0 ? 1 : month}개월`
  return `${year}년${diffMonth !== 0 ? ` ${diffMonth}개월` : ''}`
}

export const calcDateBefore = (before: string) => {
  const date = new Date(before)
  let diff = (new Date().getTime() - date.getTime()) / 1000 // 초 단위
  if (diff < 60) return `${Math.floor(diff)}초 전`
  diff = diff / 60 // 분 단위
  if (diff < 60) return `${Math.floor(diff)}분 전`
  diff = diff / 60 // 시간 단위
  if (diff < 24) return `${Math.floor(diff)}시간 전`
  diff = diff / 24 // 일 단위
  if (diff < 7) return `${Math.floor(diff)}일 전`
  diff = diff / 7 // 주 단위
  return `${Math.floor(diff)}주 전`
}

export const calcPublishedDate = (value: string) => {
  const date = new Date(value)
  const diffDay = moment(today).diff(date, 'day')
  if (diffDay > 364) return `${Math.floor(diffDay / 365)}년 전`
  if (diffDay > 29) return `${Math.floor(diffDay / 30)}달 전`
  if (diffDay > 6) return `${Math.floor(diffDay / 7)}주 전`
  if (diffDay === 0) return '오늘'
  if (diffDay <= 6) return `${diffDay}일 전`
  return ''
}

export const getDueDateMessage = (dueDate?: string) => {
  if (!dueDate) return '7일 후 자동으로 채팅이 거절돼요'
  const remainingDays = Math.ceil(
    (new Date(dueDate).getTime() - today.getTime()) / (1000 * 3600 * 24),
  )
  return remainingDays > 0
    ? `${remainingDays}일 후 자동으로 채팅이 거절돼요`
    : '오늘이 지나면 자동으로 채팅이 거절돼요'
}

export const calcTodayFromDate = (date: string) => {
  const diff = getDiffFromPast(date)
  return diff >= 0 ? `D+${diff}` : '날짜가 잘못되었습니다.'
}

export const addHighlight = (text: string, keyword?: string) => {
  const _keyword = keyword || text
  if (text.includes('highlighted')) return text
  return text.replace(
    new RegExp(_.escapeRegExp(_keyword), 'gi'),
    `<b class=highlighted>${_keyword}</b>`,
  )
}

export const getLocationName = (
  selectedLocation: Location,
  locations: Location[],
) => {
  return `${
    selectedLocation.parentId
      ? locations.find((item) => item.id === selectedLocation.parentId)?.name +
        ' '
      : ''
  }${selectedLocation.name}`
}

export const displayNumberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
