import { makeAutoObservable } from 'mobx'

export class PositionApplyModalStore {
  isOpen: boolean = false
  positionId: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  openModal(positionId: number) {
    this.positionId = positionId
    this.isOpen = true
  }

  closeModal() {
    this.isOpen = false
    this.positionId = 0
  }
}
