import React from 'react'
import {
  SectionDesc,
  SectionPaper,
  SectionTitle,
} from 'components/startup/startup-styles'
import { Column } from 'components/common/layout'
import { isEndWithConsonant } from 'utils/validations'
import { TextViewer } from 'components/common/text-editor'
import { TEXT_EDITOR_DEFAULT_VALUE } from 'infra/constants'

export const EffortForDev: React.FC<{ name: string; effortForDev: string }> = ({
  name,
  effortForDev,
}) => {
  if (!effortForDev || effortForDev === TEXT_EDITOR_DEFAULT_VALUE) return null
  return (
    <Column id='effortForDev'>
      <SectionTitle>팀원을 위한 노력</SectionTitle>
      <SectionDesc>
        {name}
        {isEndWithConsonant(name) ? '은' : '는'} 팀원을 얼마나 소중하게
        생각할까요?
      </SectionDesc>
      <SectionPaper>
        <TextViewer value={effortForDev} />
      </SectionPaper>
    </Column>
  )
}
