import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { AttachedFile, AttachedLink } from 'components/apply/attachments'
import { AttachmentWithAccessibility } from 'types/common'
import { AttachmentBlurWrapper } from 'components/scouts/common/attachment-blur-wrapper'
import { ProfileDetailSectionSubText } from 'components/scouts/search/styles'

export const Attachments: React.FC<{
  files: AttachmentWithAccessibility[]
  links: AttachmentWithAccessibility[]
  requestId?: number
  curationId?: number
  curatedAt?: string | null
}> = ({ files, links, requestId, curationId, curatedAt }) => (
  <Column style={{ gap: 8 }}>
    {files.length > 0 && (
      <AttachmentWrapper>
        <ProfileDetailSectionSubText>파일</ProfileDetailSectionSubText>
        <Column style={{ flex: 1, gap: 8 }}>
          {files.map((item) => (
            <AttachmentBlurWrapper
              key={item.id}
              isAccessible={item.isAccessible}
            >
              <AttachedFile file={item.link} />
            </AttachmentBlurWrapper>
          ))}
        </Column>
      </AttachmentWrapper>
    )}
    {links.length > 0 && (
      <AttachmentWrapper>
        <ProfileDetailSectionSubText>링크</ProfileDetailSectionSubText>
        <Column style={{ flex: 1, gap: 8 }}>
          {links.map((item) => (
            <AttachmentBlurWrapper
              key={item.id}
              isAccessible={item.isAccessible}
            >
              <AttachedLink
                link={item.link}
                requestId={requestId}
                curationId={curationId}
                curatedAt={curatedAt}
              />
            </AttachmentBlurWrapper>
          ))}
        </Column>
      </AttachmentWrapper>
    )}
  </Column>
)

const AttachmentWrapper = styled(Row)`
  gap: 16px;
  align-items: flex-start;
  flex: 1;
`
