import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BaseText, H3Text, H5Text } from 'components/common/text'
import { RoundButton } from 'components/common/button'

export const ProfileDetailSectionSubText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
  flex: none;
  line-height: 24px;
`

export const NoContentCard = styled(Column)`
  gap: 32px;
  width: 100%;
  margin: auto;
  align-items: center;
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.background4};
  padding: 56px 32px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    min-height: 300px;
  }
`

export const NoContentMainText = styled(H3Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  line-height: 39px;
  text-align: center;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

export const NoContentSubText = styled(H5Text)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: #749592;
  text-align: center;
  line-height: 1.5;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

export const NoContentButton = styled(RoundButton)`
  width: 267px;
`

export const ShadowCard = styled(Row)`
  padding: 16px 32px;
  background-color: ${(p) => p.theme.color.white1};
  border-radius: 32px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.19);
`

export const ProfileItemContainer = styled(Column)`
  gap: 24px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 16px;
  }
`
