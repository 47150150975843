//session, local storage 데이터 함수 처리

import {
  HAS_CLOSED_PROFILE_FILL_REQUEST_KEY,
  HAS_CLOSED_STARTUP_CHATTING_GUIDE_KEY,
  HAS_CLOSED_USER_CHATTING_GUIDE_KEY,
  HIDE_APP_INSTALL_PROMPT_POP_UP,
  HIDE_NATURAL_LANGUAGE_BETA_NOTICE_KEY,
  HIDE_PENALTY_NOTICE_STORAGE_KEY,
  HIDE_SCOUT_PAGE_NOTICE,
  HIDE_STARTUP_DETAIL_FORM_GUIDE_KEY,
  HIDE_STARTUP_POSITION_FORM_GUIDE_KEY,
  SCOUT_SEARCH_SESSION_KEY,
  HIDE_PROFILE_FILL_NEED_MODAL_KEY,
} from 'infra/constants'
import { HidePenaltyNoticeType } from 'types/common'

type EnvelopeStorage<T> = T | null

export const getClosedProfileFillRequestStorage = () =>
  JSON.parse(
    localStorage.getItem(HAS_CLOSED_PROFILE_FILL_REQUEST_KEY) as string,
  ) as EnvelopeStorage<number[]>

export const getScoutSearchSessionStorage = () =>
  JSON.parse(
    sessionStorage.getItem(SCOUT_SEARCH_SESSION_KEY) as string,
  ) as EnvelopeStorage<{
    id: number
    expire: string
  }>

export const getHidePenaltyNoticeStorage = () =>
  JSON.parse(
    localStorage.getItem(HIDE_PENALTY_NOTICE_STORAGE_KEY) as string,
  ) as EnvelopeStorage<{
    type: HidePenaltyNoticeType
    expire: number
  }>

export const getUserChattingGuideStorage = () =>
  JSON.parse(
    localStorage.getItem(HAS_CLOSED_USER_CHATTING_GUIDE_KEY) as string,
  ) as EnvelopeStorage<boolean>

export const getStartupChattingGuideStorage = () =>
  JSON.parse(
    localStorage.getItem(HAS_CLOSED_STARTUP_CHATTING_GUIDE_KEY) as string,
  ) as EnvelopeStorage<boolean>

export const getStartupDetailFormGuideStorage = () =>
  JSON.parse(
    localStorage.getItem(HIDE_STARTUP_DETAIL_FORM_GUIDE_KEY) as string,
  ) as EnvelopeStorage<boolean>

export const getStartupPositionFormGuideStorage = () =>
  JSON.parse(
    localStorage.getItem(HIDE_STARTUP_POSITION_FORM_GUIDE_KEY) as string,
  ) as EnvelopeStorage<{ expire: number }>

export const getNaturalLanguageInputBetaNoticeStorage = () =>
  JSON.parse(
    localStorage.getItem(HIDE_NATURAL_LANGUAGE_BETA_NOTICE_KEY) as string,
  ) as EnvelopeStorage<boolean>

export const getScoutPageNoticeStore = () =>
  JSON.parse(localStorage.getItem(HIDE_SCOUT_PAGE_NOTICE) as string)

export const getProfileFillNeedModalStorage = () =>
  JSON.parse(
    localStorage.getItem(HIDE_PROFILE_FILL_NEED_MODAL_KEY) as string,
  ) as EnvelopeStorage<boolean>

export const getAppInstallPromptPopUpStorage = () =>
  JSON.parse(
    localStorage.getItem(HIDE_APP_INSTALL_PROMPT_POP_UP) as string,
  ) as EnvelopeStorage<boolean>
