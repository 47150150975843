import { makeAutoObservable } from 'mobx'

export class PositionListStore {
  count: number = 1
  scroll: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setCount(v: number) {
    this.count = v
  }
  setScroll(v: number) {
    this.scroll = v
  }
}
