import Image from 'next/legacy/image'
import styled from 'styled-components'

export const Logo: React.FC<{
  src: string | null
  placeholder: string
  size?: number
}> = ({ src, placeholder, size = 48 }) => (
  <LogoWrapper size={size} hasImage={!!src}>
    <Image
      width={size}
      height={size}
      alt={src || placeholder}
      src={src || placeholder}
      objectFit='cover'
    />
  </LogoWrapper>
)

const LogoWrapper = styled.div<{
  size: number
  hasImage: boolean
}>`
  flex: none;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  overflow: hidden;
  border-radius: 8px;
  ${(p) => !p.hasImage && `border: 1px solid ${p.theme.color.line};`}
  background-color: ${(p) => p.theme.color.white1};
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: ${(p) => p.size - 4}px;
    height: ${(p) => p.size - 4}px;
  }
`
