import { makeAutoObservable } from 'mobx'
import {
  Condition,
  ConditionForm,
  DashboardScoutProfileItem,
  RequestFormOptions,
  ScoutRequestBase,
} from 'types/common'
import {
  displaySalaryNegotiableWithStockOption,
  getSalaryCondition,
} from 'utils/displays'
import { getMemberCountCondition } from 'utils/displays'
import { getDevCountCondition } from 'utils/displays'

type ScoutRequest = ScoutRequestBase | ConditionForm | DashboardScoutProfileItem

export class PreferenceStore {
  conditions: Condition[] = []
  scoutRequest?: ScoutRequest
  options?: RequestFormOptions

  constructor() {
    makeAutoObservable(this)
  }

  get importantConditions() {
    return this.conditions.filter(
      (cond) =>
        this.scoutRequest?.requiredConditions?.find(
          (i) => i.dataKey === cond.dataKey,
        )?.isRequired,
    )
  }

  get normalConditions() {
    return this.conditions.filter(
      (cond) =>
        !this.scoutRequest?.requiredConditions?.find(
          (i) => i.dataKey === cond.dataKey,
        )?.isRequired,
    )
  }

  setData(
    req: ScoutRequest | DashboardScoutProfileItem,
    opt?: RequestFormOptions,
  ) {
    this.scoutRequest = req
    if (opt) this.options = opt
  }

  setMultiSelectCond(dataKey: 'locations' | 'serviceAreas' | 'fundingRounds') {
    if (!this.scoutRequest) return
    const selected = this.scoutRequest[dataKey]
    if (!this.options) {
      return this.conditions.push({
        dataKey,
        value: selected?.join(', ') || '',
      })
    }
    const options = this.options[dataKey]
    const arr =
      selected?.map((item) => options.find((i) => i.id === item)?.name) || []
    let value
    if (arr.length > 3) {
      value = `${arr[0]}, ${arr[1]} 외 ${arr.length - 2}`
    } else {
      value = arr.join(', ')
    }
    this.conditions.push({ dataKey, value })
  }

  setMiscCond(
    dataKey:
      | 'shouldSupervisor'
      | 'shouldCto'
      | 'shouldStockOption'
      | 'remoteWorkPreference',
    value: string,
  ) {
    this.conditions.push({ dataKey, value })
  }

  setConditions() {
    if (!this.scoutRequest) return
    this.conditions = []
    const {
      locations,
      serviceAreas,
      memberCount,
      devCount,
      fundingRounds,
      salary,
      miscOptions,
    } = this.scoutRequest
    !!locations?.length && this.setMultiSelectCond('locations')
    !!serviceAreas?.length && this.setMultiSelectCond('serviceAreas')
    !!fundingRounds?.length &&
      fundingRounds.length < 4 &&
      this.setMultiSelectCond('fundingRounds')
    if (memberCount) {
      const value = getMemberCountCondition(memberCount.min, memberCount.max)
      value && this.conditions.push({ dataKey: 'memberCount', value })
    }
    if (devCount) {
      const value = getDevCountCondition(devCount.min, devCount.max)
      value && this.conditions.push({ dataKey: 'devCount', value })
    }
    if (salary) {
      const value = getSalaryCondition(salary.min, salary.max)
      value &&
        value !== '연봉 무관' &&
        this.conditions.push({ dataKey: 'salary', value })
    }
    if (miscOptions) {
      miscOptions.shouldSupervisor &&
        this.setMiscCond('shouldSupervisor', '사수 있음')
      miscOptions.shouldCto && this.setMiscCond('shouldCto', 'CTO 있음')
      !!miscOptions.remoteWorkPreference &&
        this.setMiscCond(
          'remoteWorkPreference',
          miscOptions.remoteWorkPreference === 'FULL_REMOTE_NECESSARY'
            ? '원격 근무만 가능'
            : '부분 원격 근무 필요',
        )
      miscOptions.shouldStockOption &&
        this.setMiscCond(
          'shouldStockOption',
          displaySalaryNegotiableWithStockOption(
            miscOptions.isSalaryNegotiableWithStockOption,
          ),
        )
    }
  }
}
