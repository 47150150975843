import React from 'react'
import { Column, Row } from 'components/common/layout'
import { ScoutRequestAiAssessment } from 'types/common'
import styled from 'styled-components'
import { Card } from 'components/common/card'
import { FiStar } from 'react-icons/fi'
import { theme } from 'infra/theme'
import { BaseText, SemiBoldText } from 'components/common/text'
import Image from 'next/legacy/image'

const ensureDecimal = (num: number) => {
  const result = num.toFixed(1) // 소수점 1자리만 남김
  return result
}

function evaluateScore(score: number, maxScore: number) {
  const percentage = (score / maxScore) * 100
  if (percentage >= 85) {
    return 'good'
  } else if (percentage < 35) {
    return 'not'
  } else {
    return 'potential'
  }
}

export const AiAssessment: React.FC<{
  data: ScoutRequestAiAssessment
}> = ({ data }) => {
  return (
    <Container>
      <Column style={{ gap: 8 }}>
        <Row style={{ gap: 4 }}>
          <FiStar fill={theme.color.gold} color={theme.color.gold} size={14} />
          <H5SemiText>{ensureDecimal(data.totalScore)}</H5SemiText>
        </Row>
        <RegularSubText>{data.summary}</RegularSubText>
      </Column>
      {data.sections.map((x) => (
        <Item key={x.description}>
          <Row style={{ flex: 'none' }}>
            <Image
              src={`/${evaluateScore(x.score, x.maxScore)}-match-square.svg`}
              alt={`/${evaluateScore(x.score, x.maxScore)}-match-square.svg`}
              width={24}
              height={24}
            />
          </Row>
          <Column style={{ gap: 8 }}>
            <SemiBoldText>{x.description}</SemiBoldText>
            <RegularSubText>{x.reason}</RegularSubText>
          </Column>
        </Item>
      ))}
    </Container>
  )
}

const Container = styled(Card)`
  gap: 24px;
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid ${(p) => p.theme.color.line};
`

const H5SemiText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h5};
`

const Item = styled(Row)`
  gap: 12px;
  align-items: flex-start;
`

const RegularSubText = styled(BaseText)`
  color: ${(p) => p.theme.color.black2};
  font-size: ${(p) => p.theme.fontWeight.regular};
  line-height: 22px;
`
