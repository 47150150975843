import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import Image from 'next/legacy/image'
import { UserStore } from 'stores/user'
import { observer } from 'mobx-react'
import { convertHyphenPhoneToNumber } from 'utils/displays'
import { convertNumberToHyphenPhone } from 'utils/displays'
import { useDebounce } from 'utils/hooks'
import { mutate } from 'swr'
import { TextInput } from 'components/common/input'

export const UserInformation: React.FC<{
  store: UserStore
  isAutosave?: boolean
}> = observer(({ store, isAutosave = false }) => {
  const saveUser = useDebounce(3000, async () => {
    try {
      store.isValidChecked()
      await store.save()
      store.setIsSaved(true)
      mutate('/accounts/me')
    } catch (e) {
      alert(e)
    }
  })
  const autosave = () => {
    if (!isAutosave) return
    store.setIsSaved(false)
    saveUser()
  }
  return (
    <ProfileSection>
      <ContentBox>
        <GroupBox>
          <ProfileContentWrapper>
            <Image
              src='/user-circle-black2.svg'
              width={24}
              height={24}
              alt='user'
            />
            <ContactInput
              maxLength={20}
              placeholder='이름 입력'
              value={store.user.name}
              onChange={(e) => {
                store.setName(e.target.value)
                autosave()
              }}
            />
          </ProfileContentWrapper>
          <ProfileContentWrapper>
            <Image src='/phone-call.svg' width={24} height={24} alt='phone' />
            <ContactInput
              type='tel'
              placeholder='- 없이 입력'
              value={convertNumberToHyphenPhone(store.user.phone)}
              maxLength={13}
              onChange={(e) => {
                store.setPhone(convertHyphenPhoneToNumber(e.target.value))
                autosave()
              }}
            />
          </ProfileContentWrapper>
          <ProfileContentWrapper>
            <Image src='/mail.svg' width={24} height={24} alt='mail' />
            <ContactInput
              placeholder='이메일 입력'
              value={store.user.email}
              onChange={(e) => {
                store.setEmail(e.target.value)
                autosave()
              }}
            />
          </ProfileContentWrapper>
        </GroupBox>
      </ContentBox>
    </ProfileSection>
  )
})

const ProfileContentWrapper = styled(Row)`
  width: 100%;
  gap: 20px;
`

const ProfileSection = styled(Row)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const GroupBox = styled(Column)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    gap: 16px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    flex-direction: column;
    gap: 10px;
  }
`

const ContentBox = styled(Column)`
  gap: 20px;
  width: 100%;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 10px;
  }
`

const ContactInput = styled(TextInput)`
  flex: 1;
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`
