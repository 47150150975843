import React from 'react'
import styled from 'styled-components'
import { LanguageSkill } from 'types/common'
import { Column, Row } from 'components/common/layout'
import { BaseText, MediumText } from 'components/common/text'
import { useIsDesktopCSR } from 'utils/hooks'
import { Tag } from 'components/common/tag'

export const LanguageSkillDetail: React.FC<{
  data: LanguageSkill
  isNarrow?: boolean
}> = ({ data, isNarrow }) => {
  const isDesktop = useIsDesktopCSR()
  const isMobileOrNarrow = isNarrow || !isDesktop
  return (
    <Container>
      <Row style={{ alignItems: 'start' }}>
        <LanguageNameText
          isNarrow={isMobileOrNarrow}
          dangerouslySetInnerHTML={{ __html: data.language.name }}
        />
        <Column style={{ gap: 10 }}>
          <Tag
            style={{ width: 'fit-content' }}
            dangerouslySetInnerHTML={{ __html: data.level.name }}
          />
          {!isMobileOrNarrow && data.description && (
            <DescriptionText
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
        </Column>
      </Row>
      {isMobileOrNarrow && data.description && (
        <DescriptionText
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      )}
    </Container>
  )
}

const Container = styled(Column)<{ isNarrow?: boolean }>`
  gap: 8px;
`

const LanguageNameText = styled(MediumText)<{ isNarrow?: boolean }>`
  ${(p) => (p.isNarrow ? `padding-right: 8px;` : `width: 85px;`)}
  line-height: 29px;
  flex: none;
`

const DescriptionText = styled(BaseText)`
  color: ${(p) => p.theme.color.black3};
  line-height: 1.6;
`
