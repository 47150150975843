import { omit } from 'lodash-es'
import { makeAutoObservable } from 'mobx'
import {
  ApplicationDetail,
  DashboardApplicationDetail,
  DashboardChatStatusType,
  DashboardScoutProposalDetail,
  ScoutProposalDetail,
} from 'types/common'

type Proposal = Partial<ScoutProposalDetail & DashboardScoutProposalDetail>
type Application = Partial<ApplicationDetail & DashboardApplicationDetail>

export class ChatRoomStore {
  data:
    | (Omit<Partial<Proposal & Application>, 'acceptedAt'> & {
        proposalAcceptedAt?: string | null // 스카우트 채팅 수락 일시
        applicationAcceptedAt?: string | null // 채용지원 최종합격 일시
      })
    | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setData(proposal?: Proposal, application?: Application) {
    this.data = omit(
      {
        ...proposal,
        ...application,
        user: {
          name: proposal?.user?.name || application?.user?.name || '',
          email: proposal?.user?.email || application?.user?.email || '',
          phone: proposal?.user?.phone || application?.user?.phone || '',
        },
        proposalAcceptedAt: proposal?.acceptedAt,
        applicationAcceptedAt: application?.acceptedAt,
      },
      ['acceptedAt'],
    )
  }

  get chatStep() {
    return this.data?.chatStep
  }

  // TODO: 기업 입장에서 rejectedAt은 자신이 거절한 것이고, 인재 입장에서 isRejected는 자신이 거절한 것이라서 isRejected라고 이름 붙이기 모호함 => 사용하고 있는 곳 전수 확인하고 변수 재정의 필요
  get isRejected() {
    return this.data?.isRejected || !!this.data?.rejectedAt
  }

  get isCanceled() {
    return this.data?.isCanceled || !!this.data?.rejectedAt
  }

  get isDoneChat() {
    return (
      this.isRejected || this.isCanceled || !!this.data?.applicationAcceptedAt
    )
  }

  get isBreakdownChat() {
    return this.chatStep === DashboardChatStatusType.BREAKDOWN
  }

  get isSucceededChat() {
    return this.chatStep === DashboardChatStatusType.SUCCEEDED
  }

  get chatPartnerName() {
    return (
      this.data?.startup?.name || this.data?.user?.name || this.data?.name || ''
    )
  }

  get appliedPosition() {
    return this.data?.position
      ? `${this.data.position.name} ‧ ${this.data.position.careerType}`
      : ''
  }

  // 채용 결정 못함
  get isNotDecisionApplication() {
    return !this.data?.rejectedAt && !this.data?.applicationAcceptedAt
  }
}
