//작업 수행 함수 처리

import { deleteRequest, postFormRequest, putRequest } from 'apis/fetcher'
import { emitter } from 'infra/event-emitter'
import { ChangeEvent } from 'react'
import {
  isPasswordValid,
  isFileMaxSize,
  isEndWithConsonant,
  isPenaltyNoticeHidden,
} from 'utils/validations'
import { mutate } from 'swr'
import {
  COOKIE_KEY_USER,
  DASHBOARD_SAVE_LIST_URL,
  HIDE_PENALTY_NOTICE_STORAGE_KEY,
  MILLISECONDS_PER_DAY,
  RECOMMEND_REQUESTED_AT_KEY,
  SCOUT_SEARCH_SESSION_KEY,
} from 'infra/constants'
import { analytics } from 'infra/analytics'
import Cookies from 'js-cookie'
import { debounce } from 'lodash-es'
import {
  AlertSettingsPayload,
  DashboardChatStatusId,
  DashboardChatStatusPayload,
  HidePenaltyNoticeType,
  NumberOrNull,
  PrivateInfoDetectedResponse,
  ScoutProfile,
  WithIdLink,
} from 'types/common'
import { getScoutSearchSessionStorage } from 'utils/storages'
import { NextRouter } from 'next/router'
import { customToast, requestToast } from 'components/common/toast'
import { ApplicationsFilterParamsProps } from 'stores/chat-rooms/chat-list-filter'
import { ProposalReqParams } from 'types/common'
import qs from 'query-string'
import {
  postRequestAnalyticsStartupScoutSearchSessionEvents,
  postRequestAnalyticsStartupScoutSearchSessions,
  postRequestDashboardAccountMeStartupPicAlert,
  postRequestDashboardScoutSaveList,
  postRequestDashboardScoutTalentRecommendRequest,
  postRequestScoutProposalAllowResume,
  postRequestStartupPositionView,
  postRequestStartupView,
} from 'apis/request'

export const mutateProposals = (proposalsParams?: ProposalReqParams) => {
  mutate(
    `/dashboard/scouts/proposals?${
      proposalsParams ? qs.stringify(proposalsParams) : ''
    }`,
  )
}

export const mutateApplications = (
  applicationsParams?: ApplicationsFilterParamsProps,
) => {
  mutate(
    `/dashboard/applications?${
      applicationsParams ? qs.stringify(applicationsParams) : ''
    }`,
  )
}

// 개별 상태 변경 시
export const updateChatStep = async (
  proposalId: NumberOrNull,
  applicationId: NumberOrNull,
  chatStep: DashboardChatStatusId,
  proposalsParams?: ProposalReqParams,
  applicationsParams?: ApplicationsFilterParamsProps,
  requestId?: number,
) => {
  try {
    const payload: DashboardChatStatusPayload[] = [
      { proposalId, applicationId, chatStep },
    ]
    await requestToast(putRequest('/dashboard/chats/step', payload), {
      loading: '변경중',
      success: '채팅 상태가 변경되었습니다.',
    })
    mutate('/dashboard/chats/step')
    if (proposalId) {
      mutateProposals(proposalsParams)
      mutate(`/dashboard/scouts/proposals/${proposalId}`)
    }
    if (applicationId) {
      mutateApplications(applicationsParams)
      mutate(`/dashboard/applications/${applicationId}`)
    }
    requestId &&
      mutate(
        `/dashboard/scouts/requests/${requestId}${
          applicationId ? `?applicationId=${applicationId}` : ''
        }`,
      )
  } catch (e) {
    alert(e)
  }
}

export const setStartupIsInterested = async (
  startupId: number,
  isInterested: boolean,
) => {
  try {
    await requestToast(
      putRequest(`/startups/${startupId}/interests`, {
        value: !isInterested,
      }).then(() =>
        emitter.startupIsInterestedChanged.emit({ startupId, isInterested }),
      ),
      {
        loading: '저장중',
        success: `${
          isInterested
            ? '해제되었습니다.'
            : '마이페이지 > 나의 활동 > 저장한 스타트업에서 확인해보세요'
        }`,
      },
    )
  } catch (err) {
    alert(err)
  }
}

export const uploadFile = async (
  e: ChangeEvent<HTMLInputElement>,
  url: string,
) => {
  try {
    if (!e.target.files) return
    if (!isFileMaxSize(e.target.files[0].size))
      throw '50MB 이하의 파일만 업로드 가능합니다.'
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    e.target.value = ''
    return await postFormRequest<WithIdLink>(url, formData).then(
      (res) => res.data,
    )
  } catch (e) {
    alert(e)
  }
}

export const savePw = async (password: string, confirmPw: string) => {
  if (!isPasswordValid(password)) {
    throw '비밀번호는 최소 8자 이상 문자, 숫자, 특수문자를 포함해야 합니다.'
  }
  if (password !== confirmPw) {
    throw '비밀번호가 일치하지 않아요.'
  }
  await requestToast(
    putRequest('/dashboard/accounts/me', {
      password,
    }),
    {
      loading: '저장중',
      success: '비밀번호가 저장되었습니다.',
    },
  )
}

export const addProfileSaveListItem = async (savedListNames: string[]) => {
  const name = prompt('새 목록 이름을 입력해주세요.')
  if (!name) return
  if (savedListNames.includes(name)) {
    alert('이미 존재하는 이름입니다.')
    addProfileSaveListItem(savedListNames)
    return
  }
  await requestToast(postRequestDashboardScoutSaveList({ name }), {
    loading: '저장중',
    success: '새 목록을 추가했습니다.',
  })
  analytics.track('click_dss_add_save_list', { name })
  return await mutate(DASHBOARD_SAVE_LIST_URL)
}

export const viewStartupPosition = (positionId: number) => {
  const isLoggedIn = !!Cookies.get(COOKIE_KEY_USER)
  isLoggedIn && postRequestStartupPositionView(positionId)
}

export const viewStartup = (startupId: number) => {
  const isLoggedIn = !!Cookies.get(COOKIE_KEY_USER)
  isLoggedIn && postRequestStartupView(startupId)
}

export const getProfileCompleteStatus = (profile?: ScoutProfile) => {
  return {
    isCompletedEducation: !!profile?.educations.length,
    isCompletedCareer:
      !!profile?.careers.length || !!profile?.otherActivities.length,
    isCompletedResume: !!profile?.files.length || !!profile?.links.length,
    isCompletedIntroduction:
      !!profile?.introduction || !!profile?.personalities.length,
    isCompletedExpectation:
      !!profile?.expectation.description ||
      !!profile?.expectation.keywords.length,
    isCompletedLanguage: !!profile?.languageSkills.length,
    isCompletedImage: !!profile?.image,
    isCompletedJobSearchingReason: !!profile?.jobSearchingReason,
  }
}

// 인재 탐색 세션 ID 생성
export const createScoutSearchSession = async () => {
  const nowTimestamp = Date.now()
  const expireTimestamp = nowTimestamp + 30 * 1000 // 30초 후 만료됨
  const session = getScoutSearchSessionStorage()
  const sessionExpireTimestamp = new Date(session?.expire || '').getTime()

  if (session && nowTimestamp < sessionExpireTimestamp) {
    // 세션 만료 시간 전에 실행시 만료 시간을 다시 30초 늘림
    sessionStorage.setItem(
      SCOUT_SEARCH_SESSION_KEY,
      JSON.stringify({
        id: session.id,
        expire: new Date(expireTimestamp),
      }),
    )
    return
  }
  if (!session || nowTimestamp > sessionExpireTimestamp) {
    // 세션이 없거나 만료 시간이 지났을 때 새로운 세션을 생성한다.
    const res = await postRequestAnalyticsStartupScoutSearchSessions()
    sessionStorage.setItem(
      SCOUT_SEARCH_SESSION_KEY,
      JSON.stringify({
        id: res.data?.sessionId,
        expire: new Date(expireTimestamp),
      }),
    )
    analytics.track('click_dsv_scout_search_sessions', {
      sessionId: res.data?.sessionId,
    })
  }
}

// 인재 탐색 이벤트 추척용
export const trackScoutSearchSessionEvent = (
  type: 'SAVE' | 'PROPOSE' | 'INPUT_CHANGE',
  data?: object,
) => {
  const session = getScoutSearchSessionStorage()
  if (!session) return
  postRequestAnalyticsStartupScoutSearchSessionEvents({
    sessionId: session.id,
    type,
    data: JSON.stringify(data),
  })
  analytics.track('searchProfile_compu_click_cvrStartBtn', {
    type,
    sessionId: session.id,
    ...(data || {}),
  })
}

export const preventClose = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = ''
}

export const preventLeave = (router: NextRouter, msg?: string) => {
  if (
    window.confirm(
      msg || '페이지를 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.',
    )
  )
    return
  router.events.emit('routeChangeError')
  if (router.asPath !== window.location.pathname) {
    window.history.pushState('', '', router.asPath)
  }
  throw 'router change aborted'
}

export const scrollEventTrack = debounce(
  (eventName: string, element?: HTMLElement) => {
    const { scrollHeight, scrollTop, clientHeight } = element
      ? element
      : document.documentElement
    const scrollDepth =
      scrollTop === 0
        ? 0
        : Math.floor((Math.ceil(scrollTop + clientHeight) / scrollHeight) * 100)
    let result = 0
    if (scrollDepth >= 90) {
      result = 100
    } else if (scrollDepth >= 75) {
      result = 75
    } else if (scrollDepth >= 50) {
      result = 50
    } else if (scrollDepth >= 25) {
      result = 25
    }
    analytics.track(eventName, { scrollDepth: result })
  },
  100,
)

export const editAlertSettings = (
  picId: number,
  payload: AlertSettingsPayload[],
  isNotiActivating: boolean,
) =>
  requestToast(postRequestDashboardAccountMeStartupPicAlert(picId, payload), {
    loading: '요청중',
    success: `알림이 ${isNotiActivating ? '설정' : '해제'}되었어요.`,
  }).then(() => mutate('/dashboard/accounts/me/startup-pic'))

export const noticePrivateInfoDetected = (
  data: PrivateInfoDetectedResponse | undefined,
  detectedPosition: string,
): boolean => {
  if (!data) return false
  const { hasPhone, hasEmail, hasLink, isLinkAdded } = data
  if (!hasPhone && !hasEmail && !hasLink) return false
  const detectedFields = []
  hasPhone && detectedFields.push('전화번호')
  hasEmail && detectedFields.push('이메일')
  hasLink && detectedFields.push('외부 링크')
  const fieldsMessage = detectedFields.join(', ')
  customToast(
    `${fieldsMessage}${
      isEndWithConsonant(fieldsMessage) ? '은' : '는'
    } ${detectedPosition}에 기재할 수 없어서 가려두었어요.${
      isLinkAdded ? `\n가려진 링크는 첨부 자료 > 링크에 저장해두었어요 :)` : ''
    }`,
  )
  return true
}

export const allowResume = async (proposalId: number) => {
  try {
    await postRequestScoutProposalAllowResume(proposalId, {
      isResumeAllowed: true,
    })
    mutate(`/scouts/proposals/${proposalId}`)
  } catch (e) {
    alert(e)
  }
  analytics.track('click_cr_attach_publish_btn')
}

export const hidePenaltyNotice = (type: HidePenaltyNoticeType) => {
  if (isPenaltyNoticeHidden(type)) return
  const params = { type, expire: Date.now() + MILLISECONDS_PER_DAY }
  localStorage.setItem(HIDE_PENALTY_NOTICE_STORAGE_KEY, JSON.stringify(params))
}

export const deleteScoutSearchTemplate = async (id: number) => {
  if (!confirm('정말로 삭제하시겠습니까?')) return
  await requestToast(
    deleteRequest(`/dashboard/scouts/search-templates/${id}`).then(() =>
      mutate('/dashboard/scouts/search-templates'),
    ),
    {
      loading: '삭제중',
      success: '필터가 삭제되었습니다.',
    },
  )
  analytics.track('searchProfile_compu_click_filter_remove_item')
  return mutate('/dashboard/scouts/search-templates')
}

export const requestRecommend = async (onRequest?: () => void) => {
  try {
    if (
      !confirm(
        '인재 추천을 요청하시겠어요?\n채용 매니저가 직접 연락 드리고 인재를 소싱해드려요.',
      )
    ) {
      return
    }
    await requestToast(
      postRequestDashboardScoutTalentRecommendRequest({ message: '' }).then(
        () => {
          localStorage.setItem(
            RECOMMEND_REQUESTED_AT_KEY,
            JSON.stringify(new Date()),
          )
          onRequest?.()
        },
      ),
      {
        loading: '전송중',
        success:
          '인재 추천 요청이 완료되었어요.\n채용 매니저가 곧 연락 드릴거예요.',
      },
      { duration: 6000 },
    )
  } catch (e) {
    alert(e)
  }
}

export const scrollIntoView = (id: string) => {
  setTimeout(() => {
    document
      .getElementById(id)
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, 100)
}
