import styled from 'styled-components'
import { Column } from 'components/common/layout'
import React from 'react'
import { TextArea } from 'components/common/input'
import { ApplyStore } from 'stores/apply'
import { observer } from 'mobx-react'
import { useDebounce, useIsDesktop } from 'utils/hooks'
import moment from 'moment'
import { SmallText } from 'components/common/text'

export const CoverLetter: React.FC<{ store: ApplyStore; startupName: string }> =
  observer(({ store, startupName }) => {
    const isDesktop = useIsDesktop()
    const tempDebounce = useDebounce(2000, () => store.saveTempCoverLetter())
    return (
      <Column>
        <TextArea
          height={isDesktop ? 115 : 166}
          placeholder={`지원하게 된 동기 등 ${startupName}에 어필하고 싶은 내용을 작성해주세요.`}
          value={store.coverLetter || ''}
          maxLength={2000}
          onChange={(e) => {
            store.setCoverLetter(e.target.value)
            store.setIsTempSaving(true)
            tempDebounce()
          }}
        />
        <SubText>
          {store.isTempSaving !== null &&
            (store.isTempSaving
              ? '임시저장 중'
              : `임시저장 완료 ${moment(store.tempDate).format(
                  'HH:mm:ss, YYYY-MM-DD',
                )}`)}
        </SubText>
      </Column>
    )
  })

const SubText = styled(SmallText)`
  color: ${(p) => p.theme.color.gray2};
  margin-top: 10px;
  text-align: end;
`
