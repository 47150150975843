import React, { useMemo } from 'react'
import { Column } from 'components/common/layout'
import { useDebounce } from 'utils/hooks'
import { ConditionItem } from 'components/scouts/proposal/condition-item'
import { ProposalStore } from 'stores/scouts/proposal'
import { observer } from 'mobx-react'
import { PositionSelector } from 'components/scouts/proposal/position-selector'
import { useBaseInfo, useDashboardScoutPositions } from 'apis/hooks'
import { MainText, SubText } from 'components/dashboard/scouts/proposal/styles'
import { getConditions, getFlattenedData } from 'utils/formatters'

export const ProposalWriteCondition: React.FC<{
  proposalStore: ProposalStore
}> = observer(({ proposalStore }) => {
  const { data: baseInfo } = useBaseInfo()
  const { data: positions } = useDashboardScoutPositions(
    proposalStore.request.id,
  )

  const flattenedLocations = useMemo(
    () =>
      getFlattenedData(
        baseInfo?.locations || [],
        proposalStore.request.locations || [],
      ),
    [baseInfo?.locations, proposalStore.request.locations],
  )

  const requireConditions = getConditions({
    profile: {
      ...proposalStore.request,
      locations: flattenedLocations,
    },
    isRequired: true,
  })
  const normalConditions = getConditions({
    profile: {
      ...proposalStore.request,
      locations: flattenedLocations,
    },
    isRequired: false,
  })

  const saveDebounce = useDebounce(1500, () => proposalStore.save())
  return (
    <Column style={{ gap: 40 }}>
      <Column style={{ gap: 16 }}>
        <MainText>만족 조건 체크</MainText>
        <Column style={{ gap: 24 }}>
          <Column style={{ justifyContent: 'start', gap: 8 }}>
            <SubText>{`${proposalStore.request.name}님에게 정말 중요한 조건들`}</SubText>
            {requireConditions.map((cond) => (
              <ConditionItem
                key={cond.dataKey}
                cond={cond}
                proposalStore={proposalStore}
                save={saveDebounce}
              />
            ))}
          </Column>
          <Column style={{ justifyContent: 'start', gap: 8 }}>
            <SubText>{`${proposalStore.request.name}님에게 협상 가능한 조건들`}</SubText>
            {normalConditions.map((cond) => (
              <ConditionItem
                key={cond.dataKey}
                cond={cond}
                proposalStore={proposalStore}
                save={saveDebounce}
              />
            ))}
          </Column>
        </Column>
      </Column>
      {positions && positions.length > 0 && (
        <Column style={{ gap: 16 }}>
          <MainText>연관 채용 공고</MainText>
          <PositionSelector proposalStore={proposalStore} />
        </Column>
      )}
    </Column>
  )
})
