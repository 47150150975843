import { makeAutoObservable, toJS } from 'mobx'
import { User } from 'types/common'
import { putFormRequest } from 'apis/fetcher'
import { isEmailValid, isNameValid, isPhoneValid } from 'utils/validations'

export class UserStore {
  user: User
  isLoading: boolean = false
  isSaved: boolean = false

  constructor(user: User) {
    this.user = toJS(user)
    makeAutoObservable(this)
  }

  setUser(user: User) {
    this.user = user
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  setIsSaved(isSaved: boolean) {
    this.isSaved = isSaved
  }

  setEmail(email: string) {
    this.user.email = email
  }

  setName(name: string) {
    this.user.name = name
  }

  setPhone(phone: string) {
    this.user.phone = phone
  }

  isValidChecked() {
    if (!isNameValid(this.user.name)) throw '이름을 확인해주세요.'
    if (!isEmailValid(this.user.email)) throw '이메일을 확인해주세요.'
    if (!isPhoneValid(this.user.phone)) throw '휴대폰 번호를 확인해주세요.'
  }

  async save() {
    const formData = new FormData()
    formData.append('name', this.user.name.trim())
    formData.append('email', this.user.email.trim())
    formData.append('phone', this.user.phone.trim())
    await putFormRequest('/accounts/me', formData)
  }
}
